import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from '../../../styles/animation';
import hexToRGB from '../../../utils/hecToRGB';
import {ANIMATION_DELAY, DEVICE_WIDTH} from "../../../common/constants";


export const CalendarTitle = styled.div`    
  font-family: 'Sharp Sans Bold';
  font-size: 24px;
  line-height: 32px;
  color: #12151A;
  margin: 0;
  text-align: left;

  @media screen and (min-width: 428px) {
    width: 414px;
  }
`;

export const LinkedInNameBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CalendarPageContainer = styled.div`
  font-family: 'Sharp Sans', sans-serif;
  display:flex;
  flex-direction: row;
  @media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const LeftSideCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${p => p.isIframe ? '11px' : '24px'};
  border-right: ${p => p.separator?'1px solid #E5E7EC':''};
  @media screen and (min-width: 428px) {
    width: 414px;
    ${p => p.page==="calendar" ? 'height: 346px;':''}
  }
  @media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    border-right: none;
    padding: 24px 20px 24px 24px;
    width: calc(100vw - 50px);
  }
`;

export const RightSideCardContainer = styled.div`
  display: flex;
  flex-direction: ${p => p.isIframe && p.isMeetingConfirmedPage ? 'column-reverse' : 'column'};
  @media screen and (min-width: 428px) {
    width: 414px;
    margin-left: ${p => p.isIframe ? '12px' : '24px'};
  }
  align-items: stretch;
  @media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: 0 4px 0 36px;
    width: calc(100vw - 48px);
  }
`

export const ConfirmButtonHolder = styled.div`
  @media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    position: fixed;
    width: calc(100% - 4px);
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    padding: 18px 24px;
  }
`

export const PickYourDayText = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  margin: 8px 0 8px 0;
  text-align: start;
  font-size: 12px;
  line-height: 20px;
  color: #525F76;
  ${p => p.isIframe ? "margin-top: auto;": ""}
`;

export const YouAreBookingWithText = styled.p`
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  color: #A3AEBF;
  text-align: start;
`;

export const NameText = styled.p`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 14px;
  line-height: 150%;
  margin: 0 6px 0 0;
  text-align: start;
  color: #525F76;
`;

export const LinkedInImage = styled.img`
  width: 15px;
  cursor: pointer;
  display: block;
`

export const LinkedInImageBlue = styled.img`
  width: 15px;
  cursor: pointer;
  display: none;
`

export const AvatarBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 4px;
  width: max-content;
  cursor: pointer;
  padding: 4px 12px;
  margin-left: -20px;
  &:hover ${NameText}{
    color: #0061FF;
  }
  &:hover ${LinkedInImage}{
    display: none;
  }
  &:hover ${LinkedInImageBlue}{
    display: block;
  }
`;

export const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 8px;
`

export const SecondaryText = styled.div`
font-size: 12px;
line-height: 20px;
text-align: center;
color: ${p => p.active ? '#FFFFFF': '#A3AEBF'};
margin-top: ${p => p.topMargin ? '4px': ''};
opacity: ${p => p.disabled ? 0.5 : 1};
${p => p.width && `width: ${p.width};`}
`;

export const CalendarContainer = styled.div`
background-color: ${p => p.theme.palette.blue5};
padding: 30px 0 20px;
border-radius: 20px;
position: relative;
overflow: hidden;
`;

export const CalendarCloseIcon = styled.img`
position: absolute;
right:20px;
top: 20px;
width: 11px;
cursor: pointer;
`;

export const LoadingOverlay = styled.div`
position: absolute;
top: 0;
bottom: 0;
opacity: 1;
z-index: 150;
background: #ffffff1A;
width: 100%;
cursor: wait;
`;

export const CalendarFieldHeader = styled.h2`
font-weight: 500;
font-size: 12px;
text-align: start;
line-height: 20px;
color: #525F76;
margin: 0;
`;

export const FieldContainer = styled.div`
${p => p.margin && `margin: ${p.margin}`}
position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const CalendarTimeListContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
left: 358px;
top: 484px;
`;

export const CalendarDateListContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 0px 6px;
align-items: center;
width: 392px;
height: 100px;
left: 358px;
top: 484px;
background: #FFFFFF;
border: 1px solid #EDEFF1;
box-sizing: border-box;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
border-radius: 15px;
@media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
  width: calc(100vw - 64px);
}
`;

export const ArrowContainer = styled.div`
position: relative;
height: ${p => p.height ? p.height : '32px'};
width: ${p => p.width ? p.width : '32px'};
display: flex;
border-radius: 50%;
background: ${p => p.disabled ? '#F5F6FA':'#F2F6FF'};
border: ${p => p.disabled ? '1px solid #E5E7EC':'1px solid #ADC5FF;'};  
flex-shrink: 0;
margin-left: ${p => p.left ? '-24px' : '0'};
margin-right: ${p => p.right ? '-24px' : '0'};
justify-content: center;
align-items: center;
cursor: pointer;
img {
  width: 12px;
  height: 12px;
  position: relative;
}
`;

export const CalendarDateScrollContainer = styled.div`
display: flex;
width: 100%;
overflow-x: scroll;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
&::-webkit-scrollbar {
  display: none;
}
`;

export const CalendarTimeScrollContainer = styled.div`
overflow-y: auto;
display: grid;
max-height: 224px;
&::-webkit-scrollbar-thumb {
  background-color: #7585A0;
  border-radius: 8px;
}
&::-webkit-scrollbar-track {
  background: #E5E7EC;
  border-radius: 12px;
}
&::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
@media screen and (min-width: 428px) {
  grid-template-columns: 1fr 1fr;
  width: 414px;
}
@media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
  grid-template-columns: 1fr;
  width: 100%;
  padding-right: 24px;
  max-height: ${p => p.leftSideContainerHeight ? `calc(100vh - ${p.leftSideContainerHeight+142}px) !important` : ""};
  min-height: unset;
}
`;

export const DescriptionBar = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 12px;
  line-height: 20px;
  color: #525F76;
  text-align: start;
  margin: 12px 0 8px 0;
`;

export const Label = styled.div`
  text-align: left;
`;

export const AdditionalFieldContainer = styled.div`
// margin-bottom: ${p => p.containsError ? '4px':'24px'};
  height: 100px;
  min-height: 76px;
&:last-child {
  height: auto;
}
`

export const EnterDetailsScrollContainer = styled.div`
overflow-y: auto;
display: flex;
flex-wrap: wrap;
margin-top: 6px;
&::-webkit-scrollbar-thumb {
  background-color: #7585A0;
  border-radius: 8px;
}
&::-webkit-scrollbar-track {
  background: #E5E7EC;
  border-radius: 12px;
}
&::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

@media screen and (min-width: 428px) {
  width: 414px;
  height: 278px;
}

@media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
  padding-right: 28px;
  max-height: ${p => p.leftSideContainerHeight ? `calc(100vh - ${p.leftSideContainerHeight+120}px) !important` : ""};
  min-height: unset;
}
  
`;

export const BoldSpan = styled.span`
font-family: "Sharp Sans Bold" !important;
`
export const SemiBoldSpan = styled.span`
font-family: "Sharp Sans Semi Bold" !important;
`

export const Date = styled.div`
font-size: 20px;
line-height: 26px;
text-align: center;
display: flex;
justify-content: center;
font-family: "Sharp Sans Semi Bold" !important;
border-radius: 10px;
color: ${p => p.active ? '#FFFFFF' : '#2F3C4ECC'};
opacity: ${p => p.disabled ? 0.5 : 1};
cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
border: 1px solid transparent;
`;

export const Separator = styled.div`
  height: 36px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1px;
  margin-right: 1px;
  border-left: ${p=> p.shouldShow?'1px solid #86BEFF':'1px solid transparent'};
  opacity: 50%;
`

export const CalendarDateItemWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
height: 84px;
min-width: 46px;
position: relative;
border: ${p => p.active ? '1px solid #86BEFF': '1px solid transparent'};
border-radius: 10px;
&:hover {
  ${p => !p.disabled && !p.active && 'background: #f2f6ff'};
  ${p => !p.disabled && !p.active && 'transition: background ${ANIMATION_DELAY}ms linear'};
}
  
&:hover + ${Separator}{
  ${p => !p.disabled && !p.active && `border-left: 1px solid transparent;`};
}
  
  
background: ${p => p.active ? p.headerBackgroundColor || (p.theme.palette?p.theme.palette.blue3:'transparent') : 'transparent'};
box-shadow: ${p => p.active && `0px 4px 20px rgba(0, 97, 255, 0.3)`};
cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
${p => p.isFirst && `margin-left: 7px;`}
margin: 10px 0 10px 0;

@media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
  min-width: calc((100vw/5) - 24px);
}
`;

export const FadeToRight = styled.div`
position: absolute;
top: 0;
z-index: 12;
height: ${p => p.height ? p.height : '50px'};
width: 30px;
opacity: 0;
background: linear-gradient(to right,#EBF0F7 0%,#EBF0F700 100%);
animation: ${fadeIn} 0.4s ease-in-out 0.1s forwards;
`;

export const FadeToLeft = styled.div`
position: absolute;
top: 0;
right: 0;
z-index: 12;
height: ${p => p.height ? p.height : '50px'};
width: 30px;
background: linear-gradient(to left,#EBF0F7 0%,#EBF0F700 100%);
animation: ${fadeIn} 0.4s ease-in-out 0.1s forwards;
`;

export const CalendarTimeItemWrapper = styled.div`
display: flex;
align-items: center;
width: max-content;
margin-bottom: 16px;
@media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
  width: 100%;
  flex: 100%;
}

@media screen and (min-width: 428px) {
  ${p => p.isOdd ? `
  &:nth-last-child(-n+2) {
    margin-bottom: 0;
  }`:`&:nth-last-child(-n+1) {
    margin-bottom: 0;
  }`};
  
}
`;

const skeletonAnimation = keyframes`
 0% { background-position: -200px 0; }
 100% { background-position: calc(200px + 100%) 0; }
`

export const SkeletonDiv = styled.div`
  background-color: #2F3C4E33;
  background-image: linear-gradient( 90deg,#2F3C4E33,#2F3C4E08,#2F3C4E33 );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${skeletonAnimation} 3s infinite;
  height: ${p => p.height ? p.height : 0}px;
  width: 30px;
  margin-top: ${p => p.marginTop ? p.marginTop : 0}px;
`

export const Time = styled.div`
padding: 4px 5px;
height: 44px;
width: 187px;
border: 1px solid #86BEFF;
font-family: "Sharp Sans Semi Bold" !important;
box-sizing: border-box;
border-radius: 30px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
font-size: 12px;
line-height: 20px;
font-weight: 500;
color: ${p => p.active ? '#FFFFFF' : '#525F76'};
background: ${p => p.active ? p.headerBackgroundColor || p.theme.palette.blue3 : 'transparent'};
box-shadow: ${p => p.active && `0px 5px 12px rgba(${hexToRGB(p.headerBackgroundColor || p.theme.palette.blue3)}, 0.2)`};
opacity: ${p => p.disabled ? 0.3 : 1};
cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
&:hover {
  ${p => !p.active && `background: #f2f6ff`};
  transition: background ${ANIMATION_DELAY}ms linear;
}
@media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
  width: 100%;
  height: 44px;
}
`;

export const DashedLine = styled.div`
width: 10px;
height: 1px;
opacity: 0.2;
margin: 0 3px;
border-bottom: 1px dashed #2F3C4E;
`;

export const CalendarFieldInput = styled.input`

background: #FFFFFF;
mix-blend-mode: normal;
border: 1px solid rgba(109, 114, 120, 0.3);
box-sizing: border-box;
border-radius: 12px;
padding: 10px;
margin: 0 10px;
width: calc(100% - 20px);
outline: none;
font-size: 14px;
line-height: 17px;
color: #2F3C4E;

 &::-webkit-input-placeholder   {
  color: #49546533;
}
`;

export const BookButton = styled.button`
background: ${p => p.backgroundColor || p.theme.palette.blue3};
box-shadow: 0px 5px 10px ${p => p.boxShadowColor ? p.boxShadowColor :  `rgba(${hexToRGB(p.backgroundColor) || hexToRGB(p.theme.palette.blue3)}, 0.2)`};
border-radius: 20px;
border: none;
outline: none;
padding: 16px;
margin: 0 20px;
width: calc(100% - 40px);
color: ${p => p.textColor ? p.textColor : 'white'};
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: center;
cursor: pointer;
`;

export const CalendarSuccess = styled.div`
display: flex;
flex-direction: column;
`;

export const SkeletonDateContainer = styled.div`
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

export const SkeletonTimeContainer = styled.div`
  ${p => p.isFirst && `margin-left: 7px`}
  ${p => p.isLast && `margin-right: 7px`}
  display: flex;
  align-items: center;

`;

export const SlotUnavailableMessage = styled.div`
  color:#BF1D30;
  font-size: 14px;
  line-height: 22px;
  text-align: start;
  margin-top: 24px;
`;

export const ErrorMessage = styled.div`
font-size: 10px;
line-height: 12px;
color: #FF2838;

mix-blend-mode: normal;
opacity: 0.8;
`;

export const ErrorMessageWrapper = styled.div`
margin: ${p => p.margin ? p.margin : '4px 22px 0'};
position: ${p => p.position ? p.position : 'absolute'};
`;

export const FieldContainerHeader = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
  margin-bottom: 26px;
  @media screen and (min-width: 428px) {
    padding-right: 24px;
  }
  @media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding-right: 28px;
  }
`;

export const CalendarForm = styled.form`
margin: 0;
`;



export const CalendarClosed = styled.div`
background: #EDF0F7;
border: 1px solid  ${p => p.headerBackgroundColor || p.theme.palette.blue3};
border-radius: 5px;
padding: 7px;
align-items: center;
display: inline-flex;
cursor: pointer;
margin: ${p => p.margin};
`;

export const CalendarIconContainer = styled.div`
background:  ${p => p.headerBackgroundColor || p.theme.palette.blue3};
border-radius: 5px;
padding: 5px;
margin-right: 10px;

img {
width: 16px;
}
`;

export const CalendarCloseText = styled.p`
padding: 0;
margin: 0;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* font color */

color: #353B47;
opacity: 0.8;
`;


export const CalendarMessageContainer = styled.div`

`;

export const CalendarInfoContainer = styled.div`
display: flex;
margin-left: 6px;
align-items: center;
`;

export const CalendarActionContainer = styled.div`

`;


export const SenderImageWrapper = styled.div`
opacity: 1;
margin: ${p => p.margin};
`;

export const MessageElementWrapper = styled(({ lead, isCalendarLeftSpace, messageType, children, ...others }) => (
<div {...others}>{children}</div>
))`
// float: ${p => (p.lead ? 'right' : 'left')};
margin: 0;
margin-left: ${p => p.isCalendarLeftSpace ? '40px' : '10px'};
width: 100%;
`;


export const NoSlotsWindow = styled.div`
  position: relative;
  padding-top: 33px;
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 20px;
  }
`;
