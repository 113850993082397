
import isEmail from 'validator/lib/isEmail';
import isStringEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';



export const checkFieldValue = ({key, value = ''}) => {

    let error = null;
    switch(key) {
        case 'email':
            if (!isEmail(value)) {
                error = "Please enter a valid email address.";
            }
            break;
        case 'phone':
            if (!isMobilePhone(value)) {
                error = "Please enter a valid phone number.";
            }
            break;
        default: 
            if (isStringEmpty(value)) {
                error = "Please enter the details.";
            }
            break;
    }

    return error
} 


export const  isEmpty = (val) => {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
}
