import React from "react";
import styled from 'styled-components';
import {DEVICE_WIDTH} from "../../../common/constants";

const InputField = styled.input`
  height: 44px;
  background: #FFFFFF;
  border: ${p => p.errored ? "1px solid #dc3545" : "1px solid #7585A0"};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 0 10px 12px;
  font-size: 16px;
  font-family: 'Sharp Sans';
  line-height: 24px;
  color: #525F76;
  &:hover, &:focus{
    border: 1px solid #0061FF;
    outline: none !important;
    
  }
  &:focus-visible{
    border: 1px solid #0061FF;
    outline: none !important;
  }
  
  @media (min-width: 428px) {
    width: 392px;
  }
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: calc(100vw - 80px);
  }
`;

const InputFieldContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 8px;
`;

const InputBar = ({placeholder, onChange, name, type, key, onBlur, value, errored}) => {
    return(
        <InputFieldContainer>
            <InputField placeholder={placeholder} onChange={onChange} name={name} type={type} key={key} onBlur={onBlur} value={value} errored={errored}/>
        </InputFieldContainer>
    )

}

export default InputBar;