import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import defaultUser from './../../../assets/icons/ContactAvatar.svg'

export const AvatarWrapper = styled.div`
  position: relative;
  border-radius: 50px;
  margin-left: ${p => p.marginLeft || '0'};
  margin-right: ${p => p.marginRight || '0'};
  z-index: ${p => p.zIndex || '1'};
  box-shadow: ${p => p.theme.boxShadow.one};
  padding: ${p => p.isBot ? '5px' : '0px'};
  ${p => `background: ${p.bgColor};`}
  height: ${p => `${parseInt(p.size)}px`}
  width: ${p => `${parseInt(p.size)}px`}
`;

const AvatarInitials = styled.div`
  border-radius: 50px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 24px;
  background: #D9F2FF;
  color: #0C364C;
`

const getInitials = name => {
    if(!name || typeof name !=="string") return;
    let initials = name.split(' ').map(ele => ele.charAt(0)).join('');
    if(initials.length > 2) return initials.substring(0,2)
    else return initials;
}


export const ZIAvatar = ({img, name, marginLeft, marginRight, zIndex, size, bgColor,...props}) => {
    return (
        <>
            {img && <Avatar src={img ? img : defaultUser} name={name} size={size ? size : 56} round={true} />}
            {!img && <AvatarInitials>{getInitials(name)}</AvatarInitials>}
        </>
    );
}

export default ZIAvatar;
