import React from "react";
import styled from 'styled-components';
import ZILogo from '../../../assets/images/zi-logo.png'
import {ReactComponent as MarketingOSLogo} from "../../../assets/icons/marketingOS.svg";

const PoweredByZIContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
`;

const PoweredByText = styled.div`
    margin-right: 8px;
    font-size: 12px;
    line-height: 20px;
    font-family: "Sharp Sans Semi Bold" !important;
    color: #FFFFFF;
`;

const MeetingText = styled.div`
    margin-left: 8px;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    font-family: "Sharp Sans Bold" !important;
`;

const ZILogoImage = styled.img`
  width: 36px;
  height: 36px;
`;

const PoweredByZI = () => {
    return(
        <PoweredByZIContainer>
            <PoweredByText>Powered By</PoweredByText>
            <MarketingOSLogo />
        </PoweredByZIContainer>
    )
}

export default PoweredByZI;