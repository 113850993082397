import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Popover from '@mui/material/Popover';

import TimeIcon from './../../../assets/icons/Time.svg';
import WorldClockIcon from './../../../assets/icons/World clock.png';
import WorldClockIconDark from './../../../assets/icons/World clock dark.png';
import {convertToMins, convertToDayTime} from '../../containers/CalendarPage/utils'
import SearchIcon from '../../../assets/icons/search-icon.png'
import {getDateWithUTCOffset} from '../../containers/CalendarPage/utils';
import DownArrowIcon from '../../../assets/icons/down-arrow.png';
import DownArrowIconDark from '../../../assets/icons/down-arrow-dark.png';
import BlueTick from '../../../assets/icons/BlueTick.svg';
import {ANIMATION_DELAY, DEVICE_WIDTH} from "../../../common/constants";

import { getTimeZones } from "@vvo/tzdb";
let RawTimeZones = getTimeZones();

const TimeBarHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg{
    fill: #A3AEBF;
  }
`;

const TimeZoneDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${p => p.noPopup ? '':'cursor: pointer;'}
  padding: 4px 10px;
  margin: 0px 2px;
  ${p => p.noPopup ? '':`&:hover{
    background: #F0F1F4;
    border-radius: 21px;
    transition: background ${ANIMATION_DELAY}ms linear;
  }`}
  ${p => p.noPopup ? '':`&:hover ${TimeBarText}{
    color: #525F76;
  }`}
  ${p => p.noPopup ? '':`&:hover ${Icon}{
    display: none;
  }`}
  ${p => p.noPopup ? '':`&:hover ${DarkIcon}{
    display: block;
  }`}
  ${p => p.noPopup ? '':`&:hover ${DownArrow}{
    display: none;
  }`}
  ${p => p.noPopup ? '':`&:hover ${DownArrowDark}{
    display: block;
  }`}
`;

const TimeBarText = styled.p`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #A3AEBF;
  margin-left:8px;
  margin-right: ${p => p.marginRight ? p.marginRight+"px" : ''};
  margin-top: 0;
  margin-bottom: 0;
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
  display: block;
`

const DarkIcon = styled.img`
  width: 16px;
  height: 16px;
  display: none;
`


const PopoverContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 2px;
  width: 428px;
  height: 437px;
  background: white;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px){
    position: fixed;
    width: 100vw;
    bottom: 0;
    left:0;
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 50%);
    border-radius: 25px 25px 0px 0px;
  }
`;

const SearchFieldContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`;

const SearchField = styled.input`
  font-family: "Sharp Sans";
  width: 100%;
  height: 32px;
  border: 1px solid #7585A0;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  line-height: 22px;
  padding-left: 36px;
  &:focus{
    border: 2px solid #7585A0;
  }
`;

const SearchIconHolder = styled.img`
  width: 17px;
  height: 17px;
  position: absolute;
  margin-top: 8px;
  margin-left: 8px;
`

const TimeZonesContainer = styled.div`
margin-top: 8px;
  margin-left: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar-thumb {
    background-color: #7585A0;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #E5E7EC;
    border-radius: 12px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px){
    margin-right: 8px;
  }
`

const TimeZoneGroupContainer = styled.div`
  border-bottom: 1px solid #D9DDE4;
  padding-right: 8px;
`

const TimeZoneGroupHeader = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 16px;
  margin-top: 24px;
  line-height: 24px;
  margin-bottom: 22px;
`
const TimeZonesValues = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 22px;
  cursor: pointer;
  ${p => p.found ? 'color: #0061FF' : ''};
  &:hover{
    color: #0061FF;
    transition: color ${ANIMATION_DELAY}ms linear;
  }
`
const DownArrow = styled.img`
  width: 8px;
  height: 4.4px;
  display: block;
`;

const DownArrowDark = styled.img`
  width: 8px;
  height: 4.4px;
  display: none;
`;

const BlueTickMark = styled.img`
    align-self: start;
    margin-top: 4px;
    justify-self: end;
    margin-right: 8px;
`;

const TimeZonesValuesHolder = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
`

const TimeBar = ({timeBarRef, isIframe, duration, timezone, clockSettings, onChangeTimeZone, noPopup}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        if(noPopup) return;
        setAnchorEl(event.currentTarget);
    };
    const processTimeZones = (timeZones = RawTimeZones) => {
        let results = {};
        let actualValue = timeZones.find((timeZone) => {
            return timezone.name === timeZone.name || timeZone.group.includes(timezone.name);
        });
            let index = timeZones.indexOf(timezone);
            let secondaryIndex = timeZones.indexOf(actualValue)
            if(index!==-1){
                let temp = timeZones[index];
                timeZones[index] = timeZones[0];
                timeZones[0] = temp;
            }
            else if(secondaryIndex !== -1){
                let temp = timeZones[secondaryIndex];
                timeZones[secondaryIndex] = timeZones[0];
                timeZones[0] = temp;
            }
        for(let i=0; i<timeZones.length; i++){
            if(!results[timeZones[i].continentName])
                results[timeZones[i].continentName]=[timeZones[i]];
            else
                results[timeZones[i].continentName].push(timeZones[i]);
        }
        return results;
    }

    let [searchValue, setSearchValue] = useState('')
    let [filteredTimeZones, setFilteredTimeZones] = useState(processTimeZones(RawTimeZones));

    useEffect(() => {
        if(searchValue !== '') {
            setFilteredTimeZones(processTimeZones(RawTimeZones.filter(ele => {
                if(ele.continentName.toLowerCase().includes(searchValue.toLowerCase()) || ele.name.toLowerCase().includes(searchValue.toLowerCase()) || ele.alternativeName.toLowerCase().includes(searchValue.toLowerCase()) || ele.abbreviation.toLowerCase().includes(searchValue.toLowerCase())) return true;
                else return false;
            })))
        }
        else setFilteredTimeZones(processTimeZones(RawTimeZones));
    }, [searchValue]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = e => {
        setSearchValue(e.target.value);
    }

    const renderTimeZones = () => {
        let firstFound = false;
        let firstArray = Object.keys(filteredTimeZones);
        let result = [firstArray.map(ele => {
            let values = filteredTimeZones[ele].map(values => {
                let found = false;
                if(!firstFound){
                    if((values.name === timezone.name) || (values.group.includes(timezone.name))){
                        found=true;
                    }
                }
                let time= getDateWithUTCOffset(values.currentTimeOffsetInMinutes)
                let fullTime = convertToDayTime(time.getHours(), time.getMinutes(), clockSettings && clockSettings.clockSettings);
                return (
                    <TimeZonesValuesHolder>
                        <TimeZonesValues found={found} key={values.name} onClick={()=>{onChangeTimeZone(values.currentTimeOffsetInMinutes, values)}}>
                            {values.abbreviation} {values.alternativeName} - {values.name} {fullTime}
                        </TimeZonesValues>
                        {found && <BlueTickMark src={BlueTick}/>}
                    </TimeZonesValuesHolder>
                )
            });
            return (
                <TimeZoneGroupContainer key={ele}>
                    <TimeZoneGroupHeader>{ele}</TimeZoneGroupHeader>
                    {values}
                </TimeZoneGroupContainer>
            );
        })];
        return (
            <div>
                {result}
            </div>
        );
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <TimeBarHolder ref={timeBarRef}>
            <Icon src={TimeIcon} />
            <TimeBarText marginRight={4}>{convertToMins(duration)}</TimeBarText>
            <TimeZoneDiv noPopup={noPopup} onClick={handleClick}>
                <Icon src={WorldClockIcon} />
                <DarkIcon src={WorldClockIconDark}/>
                {typeof timezone === "string" && <TimeBarText marginRight={5}>{timezone}</TimeBarText>}
                {typeof timezone !== "string" && <TimeBarText marginRight={5}>{`${timezone.alternativeName} (${timezone.abbreviation})`}</TimeBarText>}
                {!noPopup && <DownArrow src={DownArrowIcon} />}
                {!noPopup && <DownArrowDark src={DownArrowIconDark} />}
            </TimeZoneDiv>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PopoverContentContainer>
                    <SearchFieldContainer>
                        <SearchIconHolder src={SearchIcon}/>
                        <SearchField placeholder={'Search Timezone'} value={searchValue} onChange={handleChange}/>
                    </SearchFieldContainer>
                    <TimeZonesContainer>
                        {renderTimeZones()}
                    </TimeZonesContainer>
                </PopoverContentContainer>
            </Popover>
        </TimeBarHolder>
    )
}

export default TimeBar;