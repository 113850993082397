import React, {useState} from "react";
import styled from "styled-components";

const ToggleSwitchContainer = styled.div`
  width: 122px;
  height: 28px;
  background: #F0F1F4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
`

const Option = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding: 0 10px;
  height: 26px;
  display: flex;
  min-width: 40px;
  align-items: center;
  border-radius: 20px;
  ${p => p.active ? 'border: 1px solid #0061FF':''};
  ${p => p.active ? 'background: #FFFFFF':''};
  ${p => p.active ? 'border: 1px solid #0061FF':'border: 1px solid #F0F1F4'};
  ${p => p.active ? 'font-family: "Sharp Sans Semi Bold" !important':''};
  ${p => p.active ? 'color:#0061FF':'color:#12151A'};

`

const ToggleSwitch = ({firstOption, secondOption, activeIndex=0, setActiveIndex, isLoading}) => {
    let [active, setActive] = useState(activeIndex);

    return (
      <ToggleSwitchContainer>
          <Option active={active === 0} onClick={()=>{if(active!==0 && !isLoading){setActiveIndex(0);setActive(0)}}}>
              12 Hrs.
          </Option>
          <Option active={active === 1} onClick={()=>{if(active!==1 && !isLoading){setActiveIndex(1);setActive(1)}}}>
              24 Hrs.
          </Option>
      </ToggleSwitchContainer>
    );
}

export default ToggleSwitch;