import './App.css';
import React, {useEffect} from "react";
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import saga from '../../../saga';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../../utils/injectSaga';
import {PAGES} from "../../../common/constants";
import CalendarPage from "../CalendarPage";
import EnterDetailsPage from "../EnterDetailsPage";
import MeetingConfirmedPage from "../MeetingConfirmedPage";
import BackgroundImage from "../../components/BackgroundImage";
import LoadingPage from "../LoadingPage";
import ErrorPage from "../ErrorPage";
import UserNotFound from "../UserNotFound";
import AuthPage from "../AuthPage";
import AuthResponse from "../AuthResponse";
import {DEVICE_WIDTH} from "../../../common/constants";
import PoweredByZI from "../../components/PoweredByZI";
import {ReactComponent as WhiteCloseIcon} from "../../../assets/icons/white-close-icon.svg";
import DotLoader from "../../components/DotLoader";
import OnboardingPage from "../OnboardingPage";
import IntegrationErrorPage from "../IntegrationErrorPage";

const AppContainer = styled.div`
  display: flex;
  justify-content: ${p => (p.isIframe && p.currentPage !== PAGES.LOADING_PAGE) ? "flex-end" : "center"};
  align-items: center;
  text-align: center;
  min-height: 100vh;
  z-index: -2;
  background-color: ${p => (p.isMeetingConfirmedPage && !p.isIframe) ? '#F9FCFF' : 'transparent'};
  ${p => p.isOnboardingPage ? "background-color: #ECECEC" : ""};
  ${p => p.isIframe ? 'flex-direction : column':''};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${p => p.isErrorPage ? '' : 'align-items: start'};
  }
`;

const Card = styled.div`
  ${p => (p.isIframe && p.currentPage!==PAGES.LOADING_PAGE) ? 'background-color: #FFFFFF':''};
  ${p => (p.isIframe && p.currentPage!==PAGES.LOADING_PAGE) ? 'border: 1px solid #E8EBED':''};
  ${p => (p.isIframe && p.currentPage!==PAGES.LOADING_PAGE) ? 'box-sizing: border-box':''};
  ${p => (p.isIframe && p.currentPage!==PAGES.LOADING_PAGE) ? 'border-radius: 20px;':''};
  ${p => (p.isIframe && p.currentPage!==PAGES.LOADING_PAGE) ? 'padding:24px':''};
  ${p => p.isIframe ? 'margin-bottom: 76px' : ''};
  ${p => p.isIframe ? 'box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .5)' : ''};
  ${p => (p.isIframe && p.currentPage!==PAGES.LOADING_PAGE) ? 'width: fit-content' : ''};
`;

const CardContainer = styled.div`
  ${p => p.isIframe ? 'display: flex':''};
  ${p => p.isIframe ? 'flex-direction:column':''};
  ${p => p.isIframe ? 'align-items: flex-end':''};
  ${p => p.isIframe ? 'padding:24px':''};
`;

const CloseIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 599999;
  margin: 8px;
  cursor: pointer;
`

const App = props => {
    const {history} = props;
    useInjectSaga({ key: 'global', saga });
    const {
        currentPage,
        isIframe,
        getInitialState,
    } = props;

    useEffect(() => {
        getInitialState();
    }, [])


  return (
    <AppContainer isErrorPage={currentPage === PAGES.ERROR_PAGE} currentPage={currentPage} isOnboardingPage={currentPage === PAGES.ONBOARDING_PAGE} isMeetingConfirmedPage={currentPage === PAGES.MEETING_CONFIRMED_PAGE} isIframe={isIframe}>
        {currentPage === PAGES.LOADING_PAGE && isIframe && <DotLoader />}
        <CardContainer isIframe={isIframe} currentPage={currentPage}>
            {isIframe && currentPage!==PAGES.LOADING_PAGE && <CloseIcon onClick={() => {window.top.postMessage("SCHEDULE_CLOSE", "*")}}><WhiteCloseIcon fill={"#ffffff"}/></CloseIcon>}
            <Card isIframe={isIframe} currentPage={currentPage}>
            {currentPage === PAGES.CALENDAR_PAGE && <CalendarPage />}
            {currentPage === PAGES.ENTER_DETAILS_PAGE && <EnterDetailsPage history={history}/>}
            {currentPage === PAGES.MEETING_CONFIRMED_PAGE && <MeetingConfirmedPage isIframe={isIframe}/>}
            {(currentPage === PAGES.LOADING_PAGE && !isIframe) && <LoadingPage/>}
            {currentPage === PAGES.ERROR_PAGE && <ErrorPage/>}
            {currentPage === PAGES.USER_NOT_FOUND && <UserNotFound/>}
            {currentPage === PAGES.AUTH_PAGE && <AuthPage/>}
            {currentPage === PAGES.AUTH_RESPONSE && <AuthResponse/>}
            {currentPage === PAGES.ONBOARDING_PAGE && <OnboardingPage />}
            {currentPage === PAGES.INTEGRATION_ERROR_PAGE && <IntegrationErrorPage />}
            </Card>
        </CardContainer>
        {isIframe && currentPage !==PAGES.LOADING_PAGE && <PoweredByZI />}
        {!isIframe && <BackgroundImage isMeetingConfirmedPage={currentPage === PAGES.MEETING_CONFIRMED_PAGE}/>}
    </AppContainer>
  );
}

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        currentPage: selectors.makeSelectCurrentPage(),
        isIframe: selectors.makeSelectIsIframe(),
        meeting: selectors.makeSelectMeeting(),
        user: selectors.makeSelectUser(),
        routerId: selectors.makeSelectRouterId(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
        getInitialState: () => dispatch(actions.getInitialState.start()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
