import { combineReducers } from 'redux';
import appReducer from '../../reducers';
import { connectRouter } from 'connected-react-router';
import history from '../../utils/history';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export const createReducer = (injectedReducers = {}) => {
  const rootReducer = combineReducers({
    global: appReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
};
