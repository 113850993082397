import React from "react";
import styled from 'styled-components';
import errorImage from '../../../assets/images/errorimage.png';
import {createStructuredSelector} from "reselect";
import * as selectors from "../../../selectors";
import {connect} from "react-redux";
import {ReactComponent as MasketingOSLogo} from "../../../assets/images/marketingOS.svg";

const ErrorPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
`;

const ErrorTitle = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 18px;
  line-height: 24px;
  color: #12151A;
  margin-bottom: 4px;
`;

const ErrorDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  width: 546px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #525F76;
`;

const ErrorImage = styled.img`
  margin-bottom: 30px;
`

let IntegrationErrorPage = ({integrationErrorPageDetails}) => {
    return (
        <ErrorPageContainer>
            <MasketingOSLogo style={{position: "fixed", top: 10, left: 14}}/>
            <ErrorImage src={integrationErrorPageDetails?.image} />
            <ErrorTitle>{integrationErrorPageDetails?.header}</ErrorTitle>
            <ErrorDescription>{integrationErrorPageDetails?.subheader}</ErrorDescription>
        </ErrorPageContainer>
    )
}

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        integrationErrorPageDetails: selectors.makeSelectIntegrationErrorPageInfo(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationErrorPage);