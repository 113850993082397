import produce from 'immer';
import _ from "lodash";
import {
    SET_PAGE,
    PAGES,
    SET_BOOKING_DATE,
    SET_BOOKING_TIME,
    SET_CLOCK_SETTINGS,
    SET_IS_IFRAME,
    GET_INITIAL_STATE,
    SET_DETAILS_VALUES,
    SET_UNAVAILABLE_SLOTS,
    SET_MEETING_LINK,
    SET_MEETING_PROVIDER,
    CHANGE_TIME_ZONE_STRING,
    ERROR_PAGE_INFO,
    SET_ERROR_PAGE_INFO,
    TOKEN_VERIFIED,
    GET_MEETING_DETAILS,
    SET_BOOKING_SLOT_UNAVAILABLE,
    SET_CURRENT_END_TIME,
    SET_SLOTS,
    CHANGE_TIME_ZONES,
    CHECK_SLOT_AVAILABILITY, GET_ONBOARD_DETAILS
} from "../common/constants";

import { getTimeZones } from "@vvo/tzdb";
let timeZones = getTimeZones();

export const initialState = {
    currentPage: PAGES.LOADING_PAGE,
    bookingDate: null,
    bookingTime: null,
    details: [],
    isIframe: false,
    meetingLink: null,
    meetingProvider: null,
    checkSlotLoading: false,
    currentEndTime: null,
    timeZoneString: timeZones.find((timeZone) => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone === timeZone.name || timeZone.group.includes(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }),
    data: {
        meetingData: {},
        userData: {},
        clockSettings: 12,
        routerId: null,
    },
    unavailableSlots: [],
    token: {
        validated: false,
        provider: null
    },
    errorPage: ERROR_PAGE_INFO.LINK_EXPIRED,
    meetingDetails: {},
    bookingSlotUnavailable: false,
    currentTimeZoneOffset: (new Date().getTimezoneOffset() * -60),
    onboardUrls: null,
    integrationErrorPageDetails: {}
}

const appReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_PAGE:
                draft.currentPage = action.payload.page;
                break;
            case SET_BOOKING_DATE:
                draft.bookingDate = action.meta.Date;
                break;
            case SET_BOOKING_TIME:
                draft.bookingTime = action.meta.Time;
                break;
            case SET_CLOCK_SETTINGS:
                draft.clockSettings = action.meta.clockSettings;
                break;
            case SET_DETAILS_VALUES:
                draft.details = action.meta.Values;
                break;
            case SET_IS_IFRAME:
                draft.isIframe = action.meta.flag;
                break;
            case GET_INITIAL_STATE.SUCCESS:
                draft.data.userData = action.payload && action.payload.results && action.payload.results.user;
                draft.data.routerId = action.payload && action.payload.results && action.payload.results.routerId;
                draft.data.meetingData = action.payload && action.payload.results && action.payload.results.meeting;
                draft.unavailableSlots = [];
                break;
            case SET_UNAVAILABLE_SLOTS:
                draft.unavailableSlots.push({slot: action.meta.slot, slotTime: action.meta.slotTime});
                break;
            case SET_MEETING_LINK:
                draft.meetingLink = action.meta.link;
                break;
            case SET_MEETING_PROVIDER:
                draft.meetingProvider = action.meta.provider;
                break;
            case CHANGE_TIME_ZONE_STRING:
                draft.timeZoneString = action.meta.timezone;
                break;
            case SET_ERROR_PAGE_INFO:
                draft.errorPage = action.meta.errorPage;
                break;
            case TOKEN_VERIFIED:
                draft.token.validated = action.meta.validated;
                draft.token.provider = action.meta.provider;
                break;
            case GET_MEETING_DETAILS.SUCCESS:
                draft.meetingDetails = action.payload.results;
                break;
            case SET_BOOKING_SLOT_UNAVAILABLE:
                draft.bookingSlotUnavailable = action.payload.flag;
                break;
            case SET_CURRENT_END_TIME:
                draft.currentEndTime = action.payload.endTime;
                break;
            case SET_SLOTS:
                if(draft.data && draft.data.meetingData) {
                    draft.data.meetingData.slots = _.merge(draft.data.meetingData.slots, action.payload.slots);
                }
                break;
            case CHANGE_TIME_ZONES.START:
                draft.currentTimeZoneOffset = action.payload*60;
                break;
            default:
            case GET_ONBOARD_DETAILS.SUCCESS:
                draft.onboardUrls = action.payload?.results;
                break;
            case GET_ONBOARD_DETAILS.ERROR:
                draft.integrationErrorPageDetails = action.payload?.error;
                break;
            case CHECK_SLOT_AVAILABILITY.SUCCESS:
                if(action?.payload?.results?.user){
                    draft.data.userData = action.payload.results.user;
                }
                if(action?.payload?.results?.routerId){
                    draft.data.routerId = action.payload.results.routerId;
                }
            break;
        }
    });

export default appReducer;