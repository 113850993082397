import {
    SET_PAGE,
    SET_BOOKING_DATE,
    SET_BOOKING_TIME,
    SET_DETAILS_VALUES,
    GET_INITIAL_STATE,
    SET_CLOCK_SETTINGS,
    SET_IS_IFRAME,
    BOOK_MEETING,
    CHECK_SLOT_AVAILABILITY,
    CHANGE_TIME_ZONES,
    SET_UNAVAILABLE_SLOTS,
    SET_MEETING_LINK,
    SET_MEETING_PROVIDER,
    CHANGE_TIME_ZONE_STRING,
    GET_SLOTS,
    VERIFY_TOKEN,
    SET_ERROR_PAGE_INFO,
    TOKEN_VERIFIED,
    REDIRECT_BACK_TO_CUSTOMER,
    GET_MEETING_DETAILS,
    SET_BOOKING_SLOT_UNAVAILABLE,
    FETCH_MORE_SLOTS,
    SET_CURRENT_END_TIME, SET_SLOTS, GET_ONBOARD_DETAILS
} from "../common/constants";

import { defineAction } from '../utils/actionHelper';

export const setPage = page =>
    defineAction({
        type: SET_PAGE,
        payload: {
            page,
        },
    });

export const setCurrentEndTime = endTime =>
    defineAction({
        type: SET_CURRENT_END_TIME,
        payload: {
            endTime,
        },
    });

export const setSlots = slots =>
    defineAction({
        type: SET_SLOTS,
        payload: {
            slots,
        },
    });

export const setBookingSlotUnavailable = flag =>
    defineAction({
        type: SET_BOOKING_SLOT_UNAVAILABLE,
        payload: {
            flag,
        },
    });

export const setMeetingLink = link =>
    defineAction({
        type: SET_MEETING_LINK,
        meta: {
            link,
        },
    });

export const setMeetingProvider = provider =>
    defineAction({
        type: SET_MEETING_PROVIDER,
        meta: {
            provider,
        },
    });

export const changeTimeZoneString = timezone =>
    defineAction({
        type: CHANGE_TIME_ZONE_STRING,
        meta: {
            timezone,
        },
    });

export const setBookingDate = Date =>
    defineAction({
        type: SET_BOOKING_DATE,
        meta: {
            Date,
        },
    });

export const setErrorPageInfo = errorPage =>
    defineAction({
        type: SET_ERROR_PAGE_INFO,
        meta: {
            errorPage,
        },
    });

export const setBookingTime = Time =>
    defineAction({
        type: SET_BOOKING_TIME,
        meta: {
            Time,
        },
    });

export const setClockSettings = clockSettings =>
    defineAction({
        type: SET_CLOCK_SETTINGS,
        meta: {
            clockSettings,
        },
    });

export const setTokenValidated = meta =>
    defineAction({
        type: TOKEN_VERIFIED,
        meta,
    });

export const setDetailsValues = Values =>
    defineAction({
        type: SET_DETAILS_VALUES,
        meta: {
            Values,
        },
    });

export const setIsIframe = flag =>
    defineAction({
        type: SET_IS_IFRAME,
        meta: {
            flag,
        },
    });

export const setUnavailableSlots = (slot, slotTime) =>
    defineAction({
        type: SET_UNAVAILABLE_SLOTS,
        meta: {
            slot,
            slotTime
        },
    });

export const checkSlotAvailability = {
    start: ({meta}) =>
        defineAction({
            type: CHECK_SLOT_AVAILABILITY.START,
            meta
        }),
    success: ({ results }) =>
        defineAction({
            type: CHECK_SLOT_AVAILABILITY.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: CHECK_SLOT_AVAILABILITY.ERROR,
            payload: {
                error,
            },
        }),
};

export const getMeetingDetails = {
    start: () =>
        defineAction({
            type: GET_MEETING_DETAILS.START,
        }),
    success: results =>
        defineAction({
            type: GET_MEETING_DETAILS.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: GET_MEETING_DETAILS.ERROR,
            payload: {
                error,
            },
        }),
};

export const getOnboardDetails = {
    start: () =>
        defineAction({
            type: GET_ONBOARD_DETAILS.START,
        }),
    success: results =>
        defineAction({
            type: GET_ONBOARD_DETAILS.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: GET_ONBOARD_DETAILS.ERROR,
            payload: {
                error,
            },
        }),
};

export const changeTimeZone = {
    start: (payload) =>
        defineAction({
            type: CHANGE_TIME_ZONES.START,
            payload
        }),
    success: ({ results }) =>
        defineAction({
            type: CHANGE_TIME_ZONES.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: CHANGE_TIME_ZONES.ERROR,
            payload: {
                error,
            },
        }),
}

export const getSlots = {
    start: (payload) =>
        defineAction({
            type: GET_SLOTS.START,
            payload
        }),
    success: ({ results }) =>
        defineAction({
            type: GET_SLOTS.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: GET_SLOTS.ERROR,
            payload: {
                error,
            },
        }),
}

export const fetchMoreSlots = {
    start: (payload) =>
        defineAction({
            type: FETCH_MORE_SLOTS.START,
            payload
        }),
    success: ({ results }) =>
        defineAction({
            type: FETCH_MORE_SLOTS.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: FETCH_MORE_SLOTS.ERROR,
            payload: {
                error,
            },
        }),
}

export const getInitialState = {
    start: () =>
        defineAction({
            type: GET_INITIAL_STATE.START,
        }),
    success: ({ results }) =>
        defineAction({
            type: GET_INITIAL_STATE.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: GET_INITIAL_STATE.ERROR,
            payload: {
                error,
            },
        }),
};

export const redirectBackToCustomer = {
    start: () =>
        defineAction({
            type: REDIRECT_BACK_TO_CUSTOMER.START,
        }),
    success: ({ results }) =>
        defineAction({
            type: REDIRECT_BACK_TO_CUSTOMER.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: REDIRECT_BACK_TO_CUSTOMER.ERROR,
            payload: {
                error,
            },
        }),
};

export const verifyToken = {
    start: () =>
        defineAction({
            type: VERIFY_TOKEN.START
        }),
    success: ({ results }) =>
        defineAction({
            type: VERIFY_TOKEN.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: VERIFY_TOKEN.ERROR,
            payload: {
                error,
            },
        }),
};

export const bookMeeting = {
    start: ({ meta }) =>
        defineAction({
            type: BOOK_MEETING.START,
            meta
        }),
    success: ({ results }) =>
        defineAction({
            type: BOOK_MEETING.SUCCESS,
            payload: {
                results,
            },
        }),
    error: error =>
        defineAction({
            type: BOOK_MEETING.ERROR,
            payload: {
                error,
            },
        }),
};