import {CalendarDateItemWrapper, Date, SecondaryText} from "../../containers/CalendarPage/styled";
import React from "react";

const CalendarDateItem = ({ active, disabled, isFirst, date, isPreviousActive, day, month, onMouseEnter, onMouseLeave, onSelectSlot, arrayKey }) => {
    return (
        <CalendarDateItemWrapper
            key={arrayKey}
            disabled={disabled}
            isPreviousActive={isPreviousActive}
            onClick={!disabled ? onSelectSlot : undefined}
            isFirst={isFirst}
            active={active}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <SecondaryText width="40px" disabled={disabled} active={active} topMargin={true}>{month}</SecondaryText>
            <Date
                active={active}
                disabled={disabled}
            >
                {date}
            </Date>
            <SecondaryText margin="0" width="40px" disabled={disabled} active={active}>{day}</SecondaryText>
        </CalendarDateItemWrapper>
    );
};

export default CalendarDateItem;