import { createGlobalStyle } from 'styled-components';
import SharpSansMedium from '../assets/fonts/sharp-sans/SharpSans-medium.otf';
import SharpSansSemiBold from '../assets/fonts/sharp-sans/SharpSans-semi-bold.otf';
import SharpSansBold from '../assets/fonts/sharp-sans/SharpSans-bold.otf';

//@dev we not adding font in body since we building our widget in a div
const GlobalStyle = createGlobalStyle`
  // @font-face {
  //   font-family: "Sharp Sans";
  //   src: url(${SharpSansMedium});
  //   font-weight: normal;
  //   font-style: normal;
  // }
  //
  // @font-face {
  //   font-family: "Sharp Sans Semi Bold";
  //   src: url(${SharpSansSemiBold});
  //   font-weight: normal;
  //   font-style: normal;
  // }
  //
  // @font-face {
  //   font-family: "Sharp Sans Bold";
  //   src: url(${SharpSansBold});
  //   font-weight: normal;
  //   font-style: normal;
  // }

  body {
    margin: 0 !important;
    font-family: "Sharp Sans", "Sharp Sans Semi Bold", "Sharp Sans Bold", sans-serif;
  }
`;

export default GlobalStyle;
