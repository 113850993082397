import { defineApiActionTypes } from '../utils/actionHelper';

export const SET_PAGE = `SET_PAGE`;
export const SET_BOOKING_SLOT_UNAVAILABLE = `SET_BOOKING_SLOT_UNAVAILABLE`;
export const SET_MEETING_LINK = `SET_MEETING_LINK`;
export const SET_MEETING_PROVIDER = `SET_MEETING_PROVIDER`;
export const CHANGE_TIME_ZONE_STRING = `CHANGE_TIME_ZONE_STRING`;
export const SET_BOOKING_DATE = `SET_BOOKING_DATE`;
export const SET_BOOKING_TIME = `SET_BOOKING_TIME`;
export const SET_ERROR_PAGE_INFO = `SET_ERROR_PAGE_INFO`;
export const TOKEN_VERIFIED = `TOKEN_VERIFIED`;
export const SET_CLOCK_SETTINGS = `SET_CLOCK_SETTINGS`;
export const SET_DETAILS_VALUES = `SET_DETAILS_VALUES`;
export const SET_IS_IFRAME = `SET_IS_IFRAME`;
export const SET_UNAVAILABLE_SLOTS = `SET_UNAVAILABLE_SLOTS`;
export const GET_MEETING_DETAILS = defineApiActionTypes(`GET_MEETING_DETAILS`);
export const GET_ONBOARD_DETAILS = defineApiActionTypes(`GET_ONBOARD_DETAILS`);
export const GET_INITIAL_STATE = defineApiActionTypes(`GET_INITIAL_STATE`);
export const BOOK_MEETING = defineApiActionTypes(`BOOK_MEETING`);
export const VERIFY_TOKEN = defineApiActionTypes(`VERIFY_TOKEN`);
export const CHANGE_TIME_ZONES = defineApiActionTypes(`CHANGE_TIME_ZONES`);
export const CHECK_SLOT_AVAILABILITY = defineApiActionTypes(`CHECK_SLOT_AVAILABILITY`);
export const REDIRECT_BACK_TO_CUSTOMER = defineApiActionTypes(`REDIRECT_BACK_TO_CUSTOMER`);
export const GET_SLOTS = defineApiActionTypes(`GET_SLOTS`);
export const FETCH_MORE_SLOTS = defineApiActionTypes(`FETCH_MORE_SLOTS`);
export const SET_CURRENT_END_TIME = `SET_CURRENT_END_TIME`;
export const SET_SLOTS = `SET_SLOTS`;

export const TWO_WEEKS_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 14;

export const PAGES = {
   CALENDAR_PAGE: 'CALENDAR_PAGE',
   ENTER_DETAILS_PAGE: 'ENTER_DETAILS_PAGE',
   MEETING_CONFIRMED_PAGE: 'MEETING_CONFIRMED_PAGE',
   LOADING_PAGE: 'LOADING_PAGE',
   ERROR_PAGE: 'ERROR_PAGE',
   USER_NOT_FOUND: 'USER_NOT_FOUND',
   AUTH_PAGE: 'AUTH_PAGE',
   AUTH_RESPONSE: 'AUTH_RESPONSE',
   ONBOARDING_PAGE: 'ONBOARDING_PAGE',
   INTEGRATION_ERROR_PAGE : 'INTEGRATION_ERROR_PAGE'
}

export const ERROR_PAGE_INFO = {
   LINK_EXPIRED: {title: 'This link has expired', description: 'Reach out to the meeting host for more information'},
   LINK_INVALID: {title: 'This link is invalid', description: 'Reach out to the meeting host for more information'},
   INVALID_TOKEN: {title: 'The token is invalid', description: 'Reach out to the meeting host for more information'},
   INVALID_MEETING_ID: {title: 'The Meeting Id is invalid', description: 'Reach out to the meeting host for more information'},
   TOKEN_EXPIRED: {title: 'The token has expired', description: 'Reach out to the meeting host for more information'},
   NO_REDIRECT_URI: {title: 'Missing required parameter: redirect_uri', description: 'Reach out to the meeting host for more information'},
   AUTH_FAILED: {title: 'Authentication Failed, please try again later', description: 'Reach out to the meeting host for more information'},
   NOT_FOUND: {title: 'Error 404 : Page Not Found', description: 'Reach out to the meeting host for more information'},
   MEETING_BOOKING_FAILED: {title: 'Meeting Booking Failed', description: 'Reach out to the meeting host for more information'},
   SOMETHING_WENT_WRONG: {title: 'Error 400 : Something went wrong', description: 'Reach out to the meeting host for more information'}
}

export const DEVICE_WIDTH = {
   DESKTOP: 1024,
   TAB: 768,
   MOBILE: 428,
}

export const ANIMATION_DELAY = 250;