import { call, put, takeLatest } from 'redux-saga/effects';
import {
    BOOK_MEETING,
    GET_INITIAL_STATE,
    PAGES,
    TWO_WEEKS_IN_MILLISECONDS,
    CHECK_SLOT_AVAILABILITY,
    CHANGE_TIME_ZONES,
    GET_SLOTS, VERIFY_TOKEN, ERROR_PAGE_INFO, REDIRECT_BACK_TO_CUSTOMER, GET_MEETING_DETAILS, FETCH_MORE_SLOTS
} from "../common/constants";
import * as actions from '../actions';
import request from "../utils/request";
import queryString from 'query-string';
import {fullMonths, fullWeeks, months, weeks} from "../ui/containers/CalendarPage/utils";
import axios from "axios";
import LinkExpiredImage from "../assets/images/link-expired.svg";
import IntegrationAlreadyExistsImage from "../assets/images/calendar-already-connected.svg";
import CalendarConnectedSuccessfullyImage from "../assets/images/calendar-connected-successfully.svg";

export function* getInitialState() {
    const search = window.location.search;
    const path = window.location.pathname;
    try{
        const queryParams = yield call(queryString.parse, search);
        const isIframe = queryParams && queryParams.isIframe;
        const BookId = queryParams && queryParams.bookId;
        const formSubmissionId = queryParams && decodeURIComponent(queryParams.formSubmitId);
        const user = path.split('/')[1].toString();
        let splitPath = path.split('/').filter((e, index) => {
            if(index===0 || index===path.split('/').length-1) {
                return e!=="";
            }
            else return true;
        });
        if(splitPath.length === 3 && user?.toLowerCase()==="onboard" && path.split('/')[2].toString() === "mos" && path.split('/')[3].toString() === "connected")
        {
            let success = queryParams.success;
            if(success)
            {
                let errorDetails = {
                header: "Calendar Connected Successfully!",
                subheader: null,
                image: CalendarConnectedSuccessfullyImage
                }
                yield put(actions.getOnboardDetails.error(errorDetails));
                yield put(actions.setPage(PAGES.INTEGRATION_ERROR_PAGE));
            } else{
                let errorDetails = {
                    header: "Could not connected calendar",
                    subheader: "Please try again",
                    image: LinkExpiredImage
                }
                yield put(actions.getOnboardDetails.error(errorDetails));
                yield put(actions.setPage(PAGES.INTEGRATION_ERROR_PAGE));
            }
        }
        else if(splitPath.length === 2 && (user?.toLowerCase()==="connect" || user?.toLowerCase()==="integrations" || user?.toLowerCase()==="meeting" || user?.toLowerCase() === "onboard")){
            if(user==="connect" && path.split('/')[2].toString() === "integration"){
                yield put(actions.setPage(PAGES.AUTH_PAGE));
            }
            else if(user==="integrations" && (path.split('/')[2].toString() === "google" || path.split('/')[2].toString() === "microsoft")){
                yield call(redirectBackToCustomer);
            }
            else if(user==="meeting" && path.split('/')[2].toString() === "confirmed"){
                if (isIframe) yield put(actions.setIsIframe(true));
                yield call(getMeetingDetails);
            }
            else if(user==="onboard" && path.split('/')[2].toString() === "mos"){
                yield call(getOnboardDetails);
            }
            else{
                yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.NOT_FOUND))
                yield put(actions.setPage(PAGES.ERROR_PAGE))
            }
        }
        else if(splitPath.length === 1){
            if (isIframe) yield put(actions.setIsIframe(true));
            let startTime = parseInt(window.Date.now() / 1000);
            let currentEndTime = new window.Date();
            currentEndTime.setHours(0, 0, 0, 0);
            let endTime = parseInt((currentEndTime.getTime() + TWO_WEEKS_IN_MILLISECONDS) / 1000);
            let offset = -(currentEndTime.getTimezoneOffset() * 60);
            let params = `startTime=${startTime}&endTime=${endTime}&offset=${offset}`
            if (formSubmissionId && formSubmissionId !== "undefined") {
                params += `&formSubmitId=${formSubmissionId}`;
            }
            if (user) {
                params += `&link=${user}`
            }
            if (BookId) {
                params += `&bookId=${BookId}`;
            }
            const response = yield call(request, {
                method: 'get',
                endpoint: `api/v1/slots?${params}`,
            });
            if (response.data && response.data.meeting && response.data.meeting.startTime) {
                let results = response && response.data;
                yield put(actions.setCurrentEndTime(endTime));
                yield put(actions.getInitialState.success({results}))
                let timestamp = response.data.meeting.startTime;
                let offset = response.data.meeting.offset;
                let currentDateOffset = new Date().getTimezoneOffset() * 60;
                let date = new Date((timestamp) * 1000);
                let activeSlotTime = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
                let dateAtMidnight = new Date(date.setHours(0,0,0,0));
                let activeSlot = {
                    date: dateAtMidnight.getDate() < 10 ? '0' + dateAtMidnight.getDate() : dateAtMidnight.getDate(),
                    day: weeks[dateAtMidnight.getDay()],
                    month: months[dateAtMidnight.getMonth()],
                    fullMonth: fullMonths[dateAtMidnight.getUTCMonth()],
                    fullDay: fullWeeks[dateAtMidnight.getDay()],
                    year: dateAtMidnight.getFullYear(),
                    timestamp: parseInt(dateAtMidnight.getTime()/1000)+offset,
                }
                yield put(actions.setBookingDate(activeSlot));
                yield put(actions.setBookingTime(activeSlotTime));
                yield put(actions.setPage(PAGES.ENTER_DETAILS_PAGE));
            } else if (response.data) {
                let results = response && response.data;
                yield put(actions.setCurrentEndTime(endTime));
                yield put(actions.getInitialState.success({results}));
                yield put(actions.setPage(PAGES.CALENDAR_PAGE));
            }
        }
        else{
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.NOT_FOUND))
            yield put(actions.setPage(PAGES.ERROR_PAGE));
        }
    }
    catch(e) {
        yield put(actions.setPage(PAGES.USER_NOT_FOUND));
    }
}

export function* getSlots({meta}) {
    const search = window.location.search;
    const path = window.location.pathname;
    try{
        const queryParams = yield call(queryString.parse, search);
        const isIframe = queryParams && queryParams.isIframe;
        const formSubmissionId = queryParams && decodeURIComponent(queryParams.form_submission_id);
        const user = path.split('/')[1].toString();
        if(isIframe) yield put(actions.setIsIframe(true));
        let startTime = parseInt(window.Date.now() / 1000);
        let currentEndTime = new window.Date();
        currentEndTime.setHours(0, 0, 0, 0);
        let endTime = parseInt((currentEndTime.getTime() + TWO_WEEKS_IN_MILLISECONDS) / 1000);
        let offset = -(currentEndTime.getTimezoneOffset() * 60);
        let params = `startTime=${startTime}&endTime=${endTime}&offset=${offset}`
        if(formSubmissionId && formSubmissionId !== "undefined"){
            params+=`&formSubmissionId=${formSubmissionId}`;
        }
        else if(user){
            params+=`&link=${user}`
        }
        const response = yield call(request, {
            method: 'get',
            endpoint: `api/v1/slots?${params}`,
        });
        if(response.data)
        {
            let results = response && response.data;
            yield put(actions.setCurrentEndTime(endTime));
            yield put(actions.getInitialState.success({results}));
            yield put(actions.setPage(PAGES.CALENDAR_PAGE));
        }
    }
    catch(e) {
        yield put(actions.setPage(PAGES.USER_NOT_FOUND));
    }
}

export function* fetchMoreSlots(payload) {
    const search = window.location.search;
    const path = window.location.pathname;
    try{
        const queryParams = yield call(queryString.parse, search);
        const user = path.split('/')[1].toString();
        let startTime = payload && payload.payload && payload.payload.startTime;
        let offset = payload && payload.payload && payload.payload.offset;
        let currentEndTime = new Date(payload && (payload.payload.startTime * 1000))
        currentEndTime.setHours(0, 0, 0, 0);
        let endTime = parseInt((currentEndTime.getTime() + TWO_WEEKS_IN_MILLISECONDS) / 1000);
        let params = `startTime=${startTime}&endTime=${endTime}&offset=${offset}`
        if(user){
            params+=`&link=${user}`
        }
        const response = yield call(request, {
            method: 'get',
            endpoint: `api/v1/slots?${params}`,
        });
        if(response.data)
        {
            let slots = response && response.data && response.data.meeting && response.data.meeting.slots;
            yield put(actions.setCurrentEndTime(endTime));
            yield put(actions.setSlots(slots));
        }
    }
    catch(e) {
        yield put(actions.setPage(PAGES.USER_NOT_FOUND));
    }
}

export function* bookMeeting({ meta }) {
    try{
        const search = window.location.search;
        const queryParams = yield call(queryString.parse, search);
        const isIframe = queryParams && queryParams.isIframe;
        let {additionalFields, duration, offset, meetingTime, meetingDay, routerId} = meta;
        let data = {
            additionalFields,
            routerId,
            offset,
            duration,
            startTime: meetingDay.timestamp +meetingTime - offset
        };

        let response = yield call(request, {
            method: 'post',
            endpoint: `api/v1/slots/book`,
            config: {
                data,
            },
        });

        if(response && response.data && response.data.success === true && response.data.meetingIdToken)
            window.location = `${window.location.origin}/meeting/confirmed/?meetingIdToken=${response.data.meetingIdToken}${isIframe?'&isIframe=true':''}`;
        else {
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.MEETING_BOOKING_FAILED));
            yield put(actions.setPage(PAGES.ERROR_PAGE));
        }
    }
    catch(e) {
        console.error(e);
        if(e && e.response && e.response.data && e.response.data.type === "slot-not-available")
            yield put(actions.setBookingSlotUnavailable(true));
        else{
            yield put(actions.setPage(PAGES.ERROR_PAGE));
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.MEETING_BOOKING_FAILED))
        }
    }
}

export function* checkSlotAvailability({ meta }) {
    try{
        let {routerId, slot, slotTime, duration, offset} = meta;
        let params = `routerId=${routerId}&startTime=${slot.timestamp + slotTime - offset}&offset=${offset}&duration=${duration}`
        const response = yield call(request, {
            method: 'get',
            endpoint: `api/v1/slots/available?${params}`,
        });
        if(response && response.data && response.data.available) {
            yield put(actions.setBookingSlotUnavailable(false));
            yield put(actions.setPage(PAGES.ENTER_DETAILS_PAGE));
            yield put(actions.checkSlotAvailability.success({results: response.data}));
        }
        else if(response && response.data && response.data.available === false) {
            yield put(actions.setUnavailableSlots(slot, slotTime));
        }
    }
    catch(e){
        console.error(e);
    }
}

export function* verifyToken(){
    const search = window.location.search;
    try{
        const queryParams = yield call(queryString.parse, search);
        const token = queryParams && queryParams.token;
        const redirect_uri = queryParams && queryParams.redirect_uri;
        if(!token){
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.INVALID_TOKEN));
            yield put(actions.setPage(PAGES.ERROR_PAGE));
        }
        else if(!redirect_uri){
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.NO_REDIRECT_URI));
            yield put(actions.setPage(PAGES.ERROR_PAGE));
        }
        else {
            const response = yield call(request, {
                method: 'get',
                endpoint: `api/v1/integrate/verify?token=${token}`,
            });

            if(response && response.data && response.data.valid === true){
                let meta = {validated: true, provider: response.data.integrationProvider}
                yield put(actions.setTokenValidated(meta));
            }
        }
    }
    catch(e){
        yield put(actions.setPage(PAGES.ERROR_PAGE));
        if(e && e.response && e.response.data && e.response.data.type === "token-expired")
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.TOKEN_EXPIRED));
        else if(e && e.response && e.response.data && e.response.data.type === "invalid-token")
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.INVALID_TOKEN));
        else
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.SOMETHING_WENT_WRONG))
    }
}

export function* changeTimeZones(payload) {
    const search = window.location.search;
    const path = window.location.pathname;
    try{
        const queryParams = yield call(queryString.parse, search);
        const isIframe = queryParams && queryParams.isIframe;
        const formSubmissionId = queryParams && decodeURIComponent(queryParams.form_submission_id);
        const user = path.split('/')[1].toString();
        if(isIframe) yield put(actions.setIsIframe(true));
        let startTime = parseInt(window.Date.now() / 1000);
        let currentEndTime = new window.Date();
        currentEndTime.setHours(0, 0, 0, 0);
        let endTime = parseInt((currentEndTime.getTime() + TWO_WEEKS_IN_MILLISECONDS) / 1000);
        let offset = (payload.payload * 60);
        let params = `startTime=${startTime}&endTime=${endTime}&offset=${offset}`
        if(formSubmissionId && formSubmissionId !== undefined && formSubmissionId !== "undefined"){
            params+=`&formSubmissionId=${formSubmissionId}`;
        }
        else if(user){
            params+=`&link=${user}`
        }
        const response = yield call(request, {
            method: 'get',
            endpoint: `api/v1/slots?${params}`,
        });
        if(response.data)
        {
            let results = response && response.data;
            yield put(actions.getInitialState.success({results}))
            yield put(actions.setPage(PAGES.CALENDAR_PAGE));
        }
    }
    catch(e) {
    }
}

function* redirectBackToCustomer(){
    const search = window.location.search;
    const queryParams = yield call(queryString.parse, search);
    const state = queryParams && queryParams.state && decodeURIComponent(queryParams.state);
    const code = queryParams && queryParams.code;
    let splitState = state.split(',');
    const token = splitState[1];
    const redirect_uri = splitState[0];
    try {
        if(splitState.length !== 2){
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.AUTH_FAILED));
            yield put(actions.setPage(PAGES.ERROR_PAGE));
            return;
        }

        const response = yield call(request, {
            method: 'post',
            endpoint: `api/v1/integrate/connect`,
            config: {
                data: {
                    token,
                    integrationCode: code,
                },
            },
        });

        if(response && response.data && response.data.success===true){
            window.location=`${redirect_uri}?success=true&error=null`;
        } else {
            window.location=`${redirect_uri}?success=false&error=${response.data.type}`
        }

    }
    catch (e){
        console.error(e);
        window.location=`${redirect_uri}?success=false&error=null`
    }
}

function* getMeetingDetails() {
    const search = window.location.search;
    try{
        const queryParams = yield call(queryString.parse, search);
        const meetingIdToken = queryParams.meetingIdToken;
        let params = `id=${meetingIdToken}`;
        const response = yield call(request, {
            method: 'get',
            endpoint: `api/v1/slots/meeting?${params}`,
        });

        if(response && response.data){
            yield put(actions.getMeetingDetails.success(response.data));
            yield put(actions.setPage(PAGES.MEETING_CONFIRMED_PAGE));
        }
    }
    catch(e){
        yield put(actions.setPage(PAGES.ERROR_PAGE));
        if(e && e.response && e.response.data && e.response.data.type === "invalid-token")
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.INVALID_MEETING_ID));
        else
            yield put(actions.setErrorPageInfo(ERROR_PAGE_INFO.SOMETHING_WENT_WRONG));
    }
}

function* getOnboardDetails() {
    const search = window.location.search;
    try{
        const queryParams = yield call(queryString.parse, search);
        const token = queryParams.token;

        let config = {
            method: 'get',
            url: `https://zischeduledev.api.insent.ai/mos/v1/integrate/connect?token=${token}`
        };

        let response = yield call(axios, config);

        if(response && response.data){
            yield put(actions.getOnboardDetails.success(response.data));
            yield put(actions.setPage(PAGES.ONBOARDING_PAGE));
        }
    }
    catch(e){
        yield put(actions.setPage(PAGES.ERROR_PAGE));
        if(e.response.data.type === "integration-already-exists")
        {
            let errorDetails = {
                header: "Your calendar is already connected!",
                subheader: "No further action required from your side. You will start to get meetings routed to you as soon as prospects land up on your page.",
                image: IntegrationAlreadyExistsImage
            }
            yield put(actions.getOnboardDetails.error(errorDetails));
            yield put(actions.setPage(PAGES.INTEGRATION_ERROR_PAGE));

        }
        else if(e.response.data.type === "token-expired")
        {
            let errorDetails = {
                header: "This link has expired",
                subheader: "A new link has been sent to your e-mail",
                image: LinkExpiredImage
            }
            yield put(actions.getOnboardDetails.error(errorDetails));
            yield put(actions.setPage(PAGES.INTEGRATION_ERROR_PAGE));
        }
    }
}

export default function* appSaga() {
    yield takeLatest(GET_INITIAL_STATE.START, getInitialState);
    yield takeLatest(BOOK_MEETING.START, bookMeeting);
    yield takeLatest(CHECK_SLOT_AVAILABILITY.START, checkSlotAvailability);
    yield takeLatest(CHANGE_TIME_ZONES.START, changeTimeZones);
    yield takeLatest(GET_SLOTS.START, getSlots);
    yield takeLatest(FETCH_MORE_SLOTS.START, fetchMoreSlots);
    yield takeLatest(VERIFY_TOKEN.START, verifyToken);
    yield takeLatest(REDIRECT_BACK_TO_CUSTOMER.START, redirectBackToCustomer);
    yield takeLatest(GET_MEETING_DETAILS.START, getMeetingDetails);
}