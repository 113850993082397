//TODO: naming for variable

export default {
  palette: {
    primary: '#0A7EF9',
    secondary: '#64cffb',
    success: '#07C8A7',
    error: '#FF646F',
    green1: '#35e65d',
    grey0: '#808080',
    grey1: '#cdd8ec',
    grey2: '#f5f5f5',
    grey3: '#b5b5b5',
    grey4: '#e8e8e8',
    grey5: '#aab0bc',
    grey6: '#ECEDEF',
    grey7: '#6D7D8F',
    turqois1: '#35cce6',
    turqois2: '#a3eaf7',
    white: '#ffffff',
    red1: '#ff0000',
    red2: '#ff7070',
    blue1: '#0071b2',
    blue2: '#0084ff',
    blue3: '#0061FF',
    blue4: '#2F3C4E',
    blue5: '#edf0f7',
    black: '#000000',
    text: {
      one: '#2F3C4E',
    },
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  zIndex: {
    base: 3141590,
    base1: 3141591,
    base2: 3141592,
  },
  boxShadow: {
    one: '0 4px 10px 0 rgba(0,0,0,0.15)',
  },
};
