import React, {useEffect} from "react";
import styled from 'styled-components';
import {createStructuredSelector} from "reselect";
import * as selectors from "../../../selectors";
import {connect} from "react-redux";
import {ReactComponent as MarketingOSLogo} from "../../../assets/images/marketing-os-logo.svg";
import {ReactComponent as GoogleIcon} from "../../../assets/images/connect-google.svg";
import {ReactComponent as MicrosoftIcon} from "../../../assets/images/connect-microsoft.svg";

const OnboardingPageContainer = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px rgba(140, 142, 159, 0.2);
  border-radius: 6px;
  padding: 32px;
  text-align: start;
`

const CalendarIntegrationHeader = styled.div`
  font-family: "Sharp Sans Semi Bold";
  font-size: 20px;
  color: #12151A;
  margin: 32px 0 4px 0;
`

const CalendarIntegrationSubHeader = styled.div`
  font-size: 14px;
  color: #525F76;
  margin-bottom: 4px;
`;

const Rectangle = styled.div`
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  display: flex;
  width: 541px;
  padding: 15px 20px;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
`

const RectangleTextContainer = styled.div`
    flex: 1;
`

const RectangeTextHeader = styled.div`
  font-family: "Sharp Sans Semi Bold";
  font-size: 16px;
  line-height: 150%;
  color: #12151A;
`;

const RectangeTextSubHeader = styled.div`
  font-size: 11px;
  line-height: 20px;
  color: #525F76;
`


const ConnectButton = styled.div`
  font-family: "Sharp Sans Semi Bold";
  width: 81px;
  height: 28px;
  background: #0061FF;
  border-radius: 100px;
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

let OnboardingPage = ({onboardDetails}) => {
    return (
        <OnboardingPageContainer>
            <MarketingOSLogo/>
            <CalendarIntegrationHeader>Calendar Integration</CalendarIntegrationHeader>
            <CalendarIntegrationSubHeader>This feature will allow you to share your availability and book meetings!</CalendarIntegrationSubHeader>
            <Rectangle>
                <GoogleIcon/>
                <RectangleTextContainer>
                    <RectangeTextHeader>Google Calendar</RectangeTextHeader>
                    <RectangeTextSubHeader>Google and Gmail Calendars</RectangeTextSubHeader>
                </RectangleTextContainer>
                <ConnectButton onClick={() => {window.location = onboardDetails.googleIntegrationUrl}}>Connect</ConnectButton>
            </Rectangle>
            <Rectangle>
                <MicrosoftIcon/>
                <RectangleTextContainer>
                    <RectangeTextHeader>Office 365</RectangeTextHeader>
                    <RectangeTextSubHeader>Office 365, Outlook.com, live.com or hotmail calendar</RectangeTextSubHeader>
                </RectangleTextContainer>
                <ConnectButton onClick={() => {window.location = onboardDetails.googleIntegrationUrl}}>Connect</ConnectButton>
            </Rectangle>
        </OnboardingPageContainer>
    )
}

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        onboardDetails: selectors.makeSelectIntegrationDetails(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);