import React from "react";
import styled from "styled-components";
import {DEVICE_WIDTH} from "../../../common/constants";

const LeftLightRectangle = styled.div`
  position: fixed;
  width: 276px;
  height: 1195.12px;
  left: -207.2px;
  background: linear-gradient(319.57deg, #10CAD0 6.19%, #0061FF 100%);
  opacity: 0.05;
  transform: rotate(-13.02deg);
  z-index: ${p => p.isMeetingConfirmedPage ? '0':'-10000'};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    display:none;
  }
`;

const LeftDarkRectangle = styled.div`
  position: absolute;
  width: 276px;
  height: 1195.12px;
  left: -300px;
  top: -23.81px;
  background: linear-gradient(319.57deg, #10CAD0 6.19%, #0061FF 100%);
  opacity: 0.05;
  transform: rotate(-13.02deg);
  z-index: ${p => p.isMeetingConfirmedPage ? '0':'-10000'};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    display:none;
  }
`;

const RightLightRectangle = styled.div`
  position: absolute;
  width: 276px;
  height: 1195.12px;
  right: -190px;
  top: -85.81px;
  background: linear-gradient(319.57deg, #10CAD0 6.19%, #0061FF 100%);
  opacity: 0.05;
  transform: rotate(-13.02deg);
  z-index: ${p => p.isMeetingConfirmedPage ? '0':'-10000'};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    display:none;
  }
`;

const RightDarkRectangle = styled.div`
  position: absolute;
  width: 276px;
  height: 1195.12px;
  right: -306.8px;
  top: -240.2px;
  background: linear-gradient(319.57deg, #10CAD0 6.19%, #0061FF 100%);
  opacity: 0.05;
  transform: rotate(-13.02deg);
  z-index: ${p => p.isMeetingConfirmedPage ? '0':'-10000'};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    display:none;
  }
`;


const BackgroundImage = ({isMeetingConfirmedPage}) => {
    return (
        <>
            <LeftLightRectangle isMeetingConfirmedPage={isMeetingConfirmedPage}/>
            <LeftDarkRectangle isMeetingConfirmedPage={isMeetingConfirmedPage}/>
            <RightDarkRectangle isMeetingConfirmedPage={isMeetingConfirmedPage}/>
            <RightLightRectangle isMeetingConfirmedPage={isMeetingConfirmedPage}/>
        </>
    )
}

export default BackgroundImage;