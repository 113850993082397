import React, {useRef, useState} from "react";
import styled from "styled-components";
import {
    CalendarTitle,
    DescriptionBar, LeftSideCardContainer,
    LinkedInImage, LinkedInImageBlue,
    LinkedInNameBar,
    NameText,
    TextHolder,
    YouAreBookingWithText
} from "../CalendarPage/styled";
import MeetingConfirmedImage from '../../../assets/images/meetingconfirmed2.png'
import {createStructuredSelector} from "reselect";
import * as selectors from "../../../selectors";
import * as globalActions from "../../../actions";
import {connect} from "react-redux";
import {getFullBookedDate, getFullBookedTime, getFullBookedTimeDetails, months, weeks} from "../CalendarPage/utils";
import ZoomLogo from '../../../assets/images/zoomlogo.png'
import GoogleMeetLogo from '../../../assets/icons/google-meet.svg';
import MicrosoftTeamsLogo from '../../../assets/icons/microsoft-teams.svg';
import ZIAvatar from "../../components/ZIAvatar";
import LinkedInIcon from "../../../assets/icons/LinkedInIcon.png";
import LinkedInIconBlue from "../../../assets/icons/LinkedInIconBlue.png";
import {DEVICE_WIDTH} from "../../../common/constants";
import TimeBar from "../../components/TimeBar";
import BackIcon from "../../../assets/icons/back.svg";
import {EnterDetailsPageContainer} from "../EnterDetailsPage";
import {ReactComponent as MeetingConfirmedLogoSvg} from '../../../assets/images/meetingConfirmed.svg'

const FullBookedDateContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
`

const MeetingConfirmedTitleContainer = styled.div`
  border-bottom: 1px solid #D9DDE4;
  margin-bottom: 16px;
`

const FullBookedDateDiv = styled.div`
  width: max-content;
  font-family: "Sharp Sans Bold" !important;
  font-size: 16px;
  display:flex;
  align-items: center;
  line-height: 24px;
  &:after{
    content: '';
    border: 1px solid #A3AEBF;
    height: 66%;
    display: inline-block;
    margin-left: 7px;
  }
`;

const FullBookedDayDiv =styled.div`
  font-family: "Sharp Sans Bold" !important;
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
  color: #525F76;

`;

const FullBookedTimeDiv = styled.div`
  font-family: "Sharp Sans";
  font-size: 14px;
  line-height: 22px;
  color: #525F76;
`

export const MeetingConfirmedPageContainer = styled.div`
  width: 434px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: ${p => p.separator?'1px solid #E5E7EC':''};
  @media(max-width: ${DEVICE_WIDTH.MOBILE}px){
    margin-top: 90px;
    width: unset;
  }
  @media(min-width: 428px){
    padding-bottom: 80px;
  }
`;

const BookedTimeContainer = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 14px;
  width: 258px;
  line-height: 22px;
  color: #525F76;
  text-align: start;
  margin-bottom: 16px;
  margin-left: ${p => p.isIframe ? '12px' : '0'};
`;

const MeetingConfirmedLogo = styled.img`
  width: 306.54px;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px){
    width: 278px;
  }
`;

const MeetingConfirmedLogoIframe =styled.img`
  width: 85px;
  height: 65.18px;
`;

const ZoomImage = styled.img`
  width: 22.56px;
  height: 24px;
  margin-right: 8.44px;
`;

const MeetingConfirmedHeaderTitle = styled.div`
  font-family: "Sharp Sans Bold" !important;
  font-size: 24px;
  line-height: 32px;
  color: #0061FF;
  text-align: left;
  margin-bottom: ${p => p.isIframe ? '0' : '4px'};
  margin-top: ${p => p.isIframe ? '4px' : '0'};
  margin-left: ${p => p.isIframe ? '-16px' : '0'};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px){
    max-width: calc(100vw - 96px)
  }
`

const MeetingConfirmedTitle = styled.div`
  font-family: 'Sharp Sans Bold';
  font-size: 24px;
  line-height: 32px;
  color: #12151A;
  text-align: left;
`;

const MeetingConfirmedContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(140, 142, 159, 0.2);
  border-radius: 10px;
  padding: 24px;
  width: 386px;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px){
    width: calc(100vw - 96px)
  }
`

const MeetingLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MeetingLinkText = styled.a`
  font-size: 12px;
  line-height: 20px;
  color: #0061FF;
  text-decoration:none;
  cursor: pointer;
  word-break: break-all;
`;

const AvatarBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${p => p.isIframe ? '0px' : '16px'};
  margin-left: ${p => p.isIframe ? '12px' : '0'};
  margin-top: 16px;
  cursor: pointer;
  width: max-content;
  &:hover ${NameText}{
    color: #0061FF;
  }
  &:hover ${LinkedInImage}{
    display: none;
  }
  &:hover ${LinkedInImageBlue}{
    display: block;
  }
`;

const MeetingConfirmedHeaderSubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #525F76;
  margin-bottom: 22px;
`

const MeetingConfirmedHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const RightSideCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${p => p.isIframe && p.isMeetingConfirmedPage ? 'column-reverse' : 'column'};
  @media screen and (min-width: 428px) {
    width: 414px;
    margin-left: ${p => p.isIframe ? '12px' : '24px'};
  }
  @media screen and (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: 0 4px 0 36px;
    width: calc(100vw - 48px);
  }
`;


const MeetingConfirmedTextHeader = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 24px;
  line-height: 32px;
  color: #12151A;
  text-align: left;
  margin-bottom: 4px;
  margin-top: ${p => p.isIframe ? '4px' : '0'};
  margin-left: ${p => p.isIframe ? '-16px' : '0'};
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px){
    max-width: calc(100vw - 96px)
  }
`;



const MeetingConfirmedPage = props => {
    const {
        meetingDetails,
        isIframe,
        clockSettings,
    } = props;
    let [leftSideContainerRef, setLeftSideContainerRef] = useState(null);
    const meeting = meetingDetails && meetingDetails.meeting;
    const user = meetingDetails && meetingDetails.user;
    let offset = meeting && meeting.offset;
    let timestamp = meeting && meeting.startTime;
    let date = timestamp && new Date((timestamp + offset) * 1000);
    let endDate = meeting && meeting.endTime && new Date((meeting.endTime + offset) * 1000);
    const fullWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const fullMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let activeSlot = {
        date: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        day: weeks[date.getDay()],
        month: months[date.getMonth()],
        fullMonth: fullMonths[date.getMonth()],
        fullDay: fullWeeks[date.getDay()],
        year: date.getFullYear(),
        timestamp: parseInt(timestamp),
    }
    let activeSlotTime = date.getUTCHours() * 3600 + date.getUTCMinutes() * 60 + date.getUTCSeconds();
    let endTime = endDate.getUTCHours() * 3600 + endDate.getUTCMinutes() * 60 + endDate.getUTCSeconds();
    let meetingProvider = meeting && meeting.conferenceProvider;
    let meetingLink = meeting && meeting.meetingLink;
    let calendarTitleRef = useRef(null);
    let timeBarRef = useRef(null);
    let descriptionBarRef = useRef(null);
    let avatarBarRef = useRef(null);
    let bookedTimeContainerRef = useRef(null);
    let backButtonRef = useRef(null);
    let leftSideCardContainerRef = useRef(null);

    return isIframe ? (
        <EnterDetailsPageContainer>
            <LeftSideCardContainer isIframe={isIframe} separator={true} ref={newRef => setLeftSideContainerRef(newRef)}>
                <CalendarTitle ref={calendarTitleRef}>{meeting.subject}</CalendarTitle>
                {meeting.description && <DescriptionBar ref={descriptionBarRef}>{meeting.description}</DescriptionBar>}
                <AvatarBar onClick={ () => {window.open(user?.linkedinUrl, "_blank")} } ref={avatarBarRef}>
                    <ZIAvatar img={user?.avatar} name={user?.name}/>
                    <TextHolder>
                        <YouAreBookingWithText>You are booking with</YouAreBookingWithText>
                        <LinkedInNameBar>
                            <NameText>{user?.name}</NameText>
                            {user?.linkedinUrl && <LinkedInImage src={LinkedInIcon} />}
                            {user?.linkedinUrl && <LinkedInImageBlue src={LinkedInIconBlue} />}
                        </LinkedInNameBar>
                    </TextHolder>
                </AvatarBar>
                <BookedTimeContainer>
                    <FullBookedDateContainer>
                        <FullBookedDateDiv>{getFullBookedDate(activeSlot, activeSlotTime)}</FullBookedDateDiv>
                        <FullBookedDayDiv>{activeSlot.fullDay}</FullBookedDayDiv>
                    </FullBookedDateContainer>
                    <FullBookedTimeDiv>{getFullBookedTime(activeSlot, activeSlotTime, endTime, clockSettings?clockSettings.clockSettings:12)}</FullBookedTimeDiv>
                </BookedTimeContainer>
                <MeetingLinkContainer isIframe={isIframe}>
                    {meetingProvider === "teamsForBusiness" && <ZoomImage src={MicrosoftTeamsLogo} />}
                    {meetingProvider === "hangoutsMeet" && <ZoomImage src={GoogleMeetLogo} />}
                    {meetingProvider === "custom" && <ZoomImage src={GoogleMeetLogo} />}
                    <MeetingLinkText onClick={ () => {window.open(meetingLink, "_blank")} }>{meetingLink}</MeetingLinkText>
                </MeetingLinkContainer>
            </LeftSideCardContainer>
            <RightSideCardContainer isIframe={isIframe} ref={leftSideCardContainerRef}>
                <MeetingConfirmedLogoSvg style={{marginBottom: "8px"}}/>
                <MeetingConfirmedTextHeader isIframe={isIframe}>Meeting Confirmed!</MeetingConfirmedTextHeader>
                <MeetingConfirmedHeaderSubTitle>A calendar invite has been sent to your email</MeetingConfirmedHeaderSubTitle>
            </RightSideCardContainer>
        </EnterDetailsPageContainer>

    ) : (
        <MeetingConfirmedPageContainer>
            {!isIframe && <MeetingConfirmedLogo src={MeetingConfirmedImage}/>}
            {!isIframe && <MeetingConfirmedHeaderTitle isIframe={isIframe}>Meeting Confirmed!</MeetingConfirmedHeaderTitle>}
            <MeetingConfirmedHeaderSubTitle>A calendar invite has been sent to your email</MeetingConfirmedHeaderSubTitle>
            {isIframe && <MeetingConfirmedHeader><MeetingConfirmedLogoIframe src={MeetingConfirmedImage}/><MeetingConfirmedHeaderTitle isIframe={isIframe}>Your meeting is confirmed!</MeetingConfirmedHeaderTitle></MeetingConfirmedHeader>}
            <MeetingConfirmedContainer>
                <MeetingConfirmedTitleContainer>
                    <MeetingConfirmedTitle isIframe={isIframe}>{meeting.subject}</MeetingConfirmedTitle>
                    {meeting.description && <DescriptionBar isIframe={isIframe}>{meeting.description}</DescriptionBar>}
                    <AvatarBar onClick={ () => {window.open(user?.linkedinUrl, "_blank")} } isIframe={isIframe}>
                        <ZIAvatar img={user?.avatar} name={user?.name}/>
                        <TextHolder>
                            <YouAreBookingWithText>Meeting scheduled with</YouAreBookingWithText>
                            <LinkedInNameBar>
                                <NameText>{user?.name}</NameText>
                                {user?.linkedinUrl && <LinkedInImage src={LinkedInIcon} />}
                                {user?.linkedinUrl && <LinkedInImageBlue src={LinkedInIconBlue} />}
                            </LinkedInNameBar>
                        </TextHolder>
                    </AvatarBar>
                </MeetingConfirmedTitleContainer>
                <BookedTimeContainer>
                    <FullBookedDateContainer>
                        <FullBookedDateDiv>{getFullBookedDate(activeSlot, activeSlotTime)}</FullBookedDateDiv>
                        <FullBookedDayDiv>{activeSlot.fullDay}</FullBookedDayDiv>
                    </FullBookedDateContainer>
                    <FullBookedTimeDiv>{getFullBookedTime(activeSlot, activeSlotTime, endTime, clockSettings?clockSettings.clockSettings:12)}</FullBookedTimeDiv>
                </BookedTimeContainer>
                <MeetingLinkContainer isIframe={isIframe}>
                    {meetingProvider === "teamsForBusiness" && <ZoomImage src={MicrosoftTeamsLogo} />}
                    {meetingProvider === "hangoutsMeet" && <ZoomImage src={GoogleMeetLogo} />}
                    {meetingProvider === "custom" && <ZoomImage src={GoogleMeetLogo} />}
                    <MeetingLinkText onClick={ () => {window.open(meetingLink, "_blank")} }>{meetingLink}</MeetingLinkText>
                </MeetingLinkContainer>
            </MeetingConfirmedContainer>
        </MeetingConfirmedPageContainer>
    );
}

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        meetingDetails: selectors.makeSelectMeetingDetails(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
        setPage: payload => dispatch(globalActions.setPage(payload)),
        getMeetingDetails: () => dispatch(globalActions.getMeetingDetails.start())
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MeetingConfirmedPage);