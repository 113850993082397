import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {
    AvatarBar,
    CalendarTitle,
    DescriptionBar,
    EnterDetailsScrollContainer,
    LeftSideCardContainer,
    LinkedInImage,
    LinkedInNameBar,
    NameText,
    TextHolder,
    YouAreBookingWithText,
    Label,
    AdditionalFieldContainer, LinkedInImageBlue,
} from "../CalendarPage/styled";
import TimeBar from "../../components/TimeBar";
import ZIAvatar from "../../components/ZIAvatar";
import LinkedInIcon from "../../../assets/icons/LinkedInIcon.png";
import {createStructuredSelector} from "reselect";
import * as globalActions from "../../../actions";
import {connect} from "react-redux";
import * as selectors from "../../../selectors";
import {getFullBookedTime, getFullBookedDate, getFullBookedTimeDetails} from "../CalendarPage/utils";
import BackIcon from '../../../assets/icons/back.svg';
import InputBar from "../../components/InputBar";
import Button from "../../components/Button";
import {ANIMATION_DELAY, PAGES} from "../../../common/constants";
import {Formik} from "formik";
import LinkedInIconBlue from "../../../assets/icons/LinkedInIconBlue.png";
import {DEVICE_WIDTH} from "../../../common/constants";

export const EnterDetailsPageContainer = styled.div`
  font-family: 'Sharp Sans', sans-serif;
  display:flex;
  flex-direction: row;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ConfirmButtonHolder = styled.div`
  @media (min-width: 428px) {
    margin-top: auto;
  }
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: calc(100% - 4px);
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    padding: 18px 24px;
  }
`

export const BookedTimeContainer = styled.div`
  width: 260px;
  font-family: "Sharp Sans Bold" !important;
  font-size: 16px;
  line-height: 24px;
  color: #525F76;
  text-align: start;
  margin: 12px 0 22px 0;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: 4px;
  }
`;

const RightSideCardContainer = styled.div`
  display: flex;
  flex-direction: ${p => p.isIframe && p.isMeetingConfirmedPage ? 'column-reverse' : 'column'};
  @media (min-width: 428px) {
    width: 414px;
    margin-left: ${p => p.isIframe ? '12px' : '24px'};
  }
  align-items: stretch;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding: 0 4px 0 24px;
    width: calc(100vw - 48px);
  }
`

const FullBookedDateContainer = styled.div`
  display: flex;
`

const FullBookedDateDiv = styled.div`
  padding-right: 7px;
  width: max-content;
  display: flex;
  align-items: center;
  &:after{
    content: '';
    position: relative;
    right: -9px;
    height: 66%;
    border: 1px solid #A3AEBF;  
  }
`;

const FullBookedDayDiv =styled.div`
  font-family: "Sharp Sans Bold" !important;
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
  color: #525F76;

`;

const FullBookedTimeDiv = styled.div`
  font-family: "Sharp Sans";
  font-size: 14px;
  line-height: 22px;
  color: #525F76;
`

const BackButton = styled.div`
  width: 44px;
  height: 44px;
  border: 1px solid #86BEFF;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  cursor: pointer;
  &:hover{
    background-color: #DDE7FF;
    transition: background-color ${ANIMATION_DELAY}ms linear;
  }
  @media (min-width: 428px) {
    display: ${p => p.mobile ? "none":""};
  }
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: ${p => p.desktop ? "none":""};
    margin-right: 8px;
  }
`;

const BackButtonImage = styled.img`
margin-left: -2px;
`

const EnterDetailsText = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 12px;
  line-height: 20px;
  text-align: start;
  margin-left: 8px;
`;

const ErrorMessage = styled.div`
  font-size: 11px;
  line-height: 20px;
  color: #dc3545;
  text-align: start;
`;

const EnterDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const SlotUnavailableContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

const SlotUnavailableError = styled.div`
  font-family: Sharp Sans;
  font-size: 14px;
  line-height: 22px;
  color: #BF1D30;
  text-align: start;
  margin-bottom: 16px;
`

const SlotUnavailableBackButton = styled.button`
  border: none;
  font-family: Sharp Sans;
  background: #0061FF;
  border-radius: 51px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  width: 215px;
  height: 44px;
  color: white;
`



const EnterDetailsPage = props => {
    const {
        setPage,
        BookingDate,
        BookingTime,
        isIframe,
        meeting,
        user,
        routerId,
        bookMeeting,
        clockSettings,
        getSlots,
        timeZoneString,
        isBookingSlotUnavailable
    } = props;

    let leftSideCardContainerRef = useRef(null);
    let [scheduleEventButtonLoading, setScheduleEventButtonLoading] = useState(false);
    let enterDetailsRef = useRef(null);
    let enterDetailsScrollContainerRef = useRef(null);
    let buttonRef = useRef(null);
    let [leftSideContainerRef, setLeftSideContainerRef] = useState(null);
    let calendarTitleRef = useRef(null);
    let timeBarRef = useRef(null);
    let descriptionBarRef = useRef(null);
    let avatarBarRef = useRef(null);
    let bookedTimeContainerRef = useRef(null);
    let backButtonRef = useRef(null);
    let formRef = useRef(null);
    let [isSubmitting, setIsSubmitting] = useState(false);

    let initialValues = {};
    if(meeting.additionalFields && meeting.additionalFields.length>0){
        for (let k = 0; k < meeting.additionalFields.length; k++) {
            if(meeting.contactDetails)
                initialValues[meeting.additionalFields[k].key] = meeting.contactDetails[meeting.additionalFields[k].key]|| "";
            else
                initialValues[meeting.additionalFields[k].key] = "";
        }
    }

    useEffect(()=>{
        setScheduleEventButtonLoading(false);
    },[isBookingSlotUnavailable])


    const handleFormSubmit = (values, errors) => {
        setIsSubmitting(true);
        setScheduleEventButtonLoading(true);
        let details = [...JSON.parse(JSON.stringify(meeting.additionalFields))];
        for(let i=0; i<details.length; i++) {
            if(!values[details[i].key]) return;
            details[i].value = values[details[i].key].toString();
        }
        let meta = {};
        meta.routerId = routerId;
        meta.meetingTime = BookingTime;
        meta.meetingDay = BookingDate;
        meta.duration = meeting.duration;
        meta.offset = meeting.offset;
        meta.additionalFields = details;
        bookMeeting({meta});
    }

    const backButtonClickHandler = async () => {
        if(meeting && meeting.slots){
            setPage(PAGES.CALENDAR_PAGE);
        }
        else{
            await setPage(PAGES.LOADING_PAGE);
            await getSlots({meta: null});
        }
    }

    return (
        <EnterDetailsPageContainer>
            <LeftSideCardContainer isIframe={isIframe} separator={true} ref={newRef => setLeftSideContainerRef(newRef)}>
                <CalendarTitle ref={calendarTitleRef}>{meeting.title}</CalendarTitle>
                {meeting.description && <DescriptionBar ref={descriptionBarRef}>{meeting.description}</DescriptionBar>}
                <TimeBar noPopup={true} duration={meeting.duration} timezone={timeZoneString} timeBarRef={timeBarRef} />
                <AvatarBar onClick={ () => {window.open(user?.linkedinUrl, "_blank")} } ref={avatarBarRef}>
                    <ZIAvatar img={user?.avatar} name={user?.name}/>
                    <TextHolder>
                        <YouAreBookingWithText>You are booking with</YouAreBookingWithText>
                        <LinkedInNameBar>
                            <NameText>{user?.name}</NameText>
                            {user?.linkedinUrl && <LinkedInImage src={LinkedInIcon} />}
                            {user?.linkedinUrl && <LinkedInImageBlue src={LinkedInIconBlue} />}
                        </LinkedInNameBar>
                    </TextHolder>
                </AvatarBar>
                <BookedTimeContainer ref={bookedTimeContainerRef}>
                    <FullBookedDateContainer>
                        <FullBookedDateDiv>{getFullBookedDate(BookingDate, BookingTime)}</FullBookedDateDiv>
                        <FullBookedDayDiv>{BookingDate && BookingDate.fullDay}</FullBookedDayDiv>
                    </FullBookedDateContainer>
                    <FullBookedTimeDiv>{getFullBookedTimeDetails(BookingDate, BookingTime, meeting.duration, clockSettings?clockSettings.clockSettings:12)}</FullBookedTimeDiv>
                </BookedTimeContainer>
                {!isBookingSlotUnavailable &&
                (
                    <BackButton desktop={true} ref={backButtonRef} onClick={backButtonClickHandler}>
                        <BackButtonImage src={BackIcon}/>
                    </BackButton>
                )}
                {isBookingSlotUnavailable &&
                (
                    <SlotUnavailableContainer>
                        <SlotUnavailableError>Sorry! The selected slot is unavailable now.</SlotUnavailableError>
                        <SlotUnavailableBackButton onClick={backButtonClickHandler}>Pick Another Slot</SlotUnavailableBackButton>
                    </SlotUnavailableContainer>
                )

                }
            </LeftSideCardContainer>
            <RightSideCardContainer isIframe={isIframe} ref={leftSideCardContainerRef}>
                <EnterDetailsText ref={enterDetailsRef}></EnterDetailsText>
                <Formik
                    initialValues={initialValues}
                    validate={values => {
                        const errors = {};
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        for(let i=0; i<meeting.additionalFields.length; i++)
                        {
                            if(!values[meeting.additionalFields[i].key]) errors[meeting.additionalFields[i].key] = 'Required field';
                        }
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        handleFormSubmit(values, null);
                    }}
                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <>
                            <EnterDetailsForm ref={formRef} onSubmit={handleSubmit}>
                                <EnterDetailsScrollContainer leftSideContainerHeight={leftSideContainerRef ? leftSideContainerRef.offsetHeight : null} ref={enterDetailsScrollContainerRef}>
                                    {
                                        meeting.additionalFields.map((field, index) => {
                                        return (
                                            <AdditionalFieldContainer key={field.key} containsError={!!errors[meeting.additionalFields[index].key]}>
                                                <Label>{field.name}</Label>
                                                <InputBar
                                                    placeholder={`${field.example}`}
                                                    name={meeting.additionalFields[index].key}
                                                    type={meeting.additionalFields[index].type}
                                                    value={values[meeting.additionalFields[index].key]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errored={!!errors[meeting.additionalFields[index].key]  && !!touched[meeting.additionalFields[index].key]}
                                                />
                                                {errors[meeting.additionalFields[index].key] && touched[meeting.additionalFields[index].key] && <ErrorMessage>{errors[meeting.additionalFields[index].key]}</ErrorMessage>}
                                            </AdditionalFieldContainer>
                                        )
                                    })
                                    }
                                </EnterDetailsScrollContainer>
                                <ConfirmButtonHolder>
                                    <BackButton mobile={true} ref={backButtonRef} onClick={backButtonClickHandler}>
                                        <BackButtonImage src={BackIcon}/>
                                    </BackButton>
                                    <Button loading={scheduleEventButtonLoading} slotUnavailable={isBookingSlotUnavailable} type="submit " mobileWidth={'calc(100vw - 100px)'}buttonRef={buttonRef} isSubmitting={isSubmitting} text="Schedule Event"/>
                                </ConfirmButtonHolder>
                            </EnterDetailsForm>
                        </>
                    )}
                </Formik>
            </RightSideCardContainer>
        </EnterDetailsPageContainer>
    )
}

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        isIframe: selectors.makeSelectIsIframe(),
        BookingDate: selectors.makeSelectBookingDate(),
        BookingTime: selectors.makeSelectBookingTime(),
        meeting: selectors.makeSelectMeeting(),
        user: selectors.makeSelectUser(),
        routerId: selectors.makeSelectRouterId(),
        clockSettings: selectors.makeSelectClockSettings(),
        timeZoneString: selectors.makeSelectTimeZoneString(),
        isBookingSlotUnavailable: selectors.makeSelectIsBookingSlotUnavailable(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
        setPage: payload => dispatch(globalActions.setPage(payload)),
        setDetails: payload => dispatch(globalActions.setDetailsValues(payload)),
        bookMeeting: ({meta}) => dispatch(globalActions.bookMeeting.start({meta})),
        getSlots: ({meta}) => dispatch(globalActions.getSlots.start({meta}))
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(EnterDetailsPage);