import React from "react";
import styled from 'styled-components';
import errorImage from '../../../assets/images/errorimage.png';

const ErrorPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
`;

const ErrorTitle = styled.div`
  font-family: "Sharp Sans Semi Bold" !important;
  font-size: 18px;
  line-height: 24px;
  color: #12151A;
  margin-bottom: 12px;
`;

const ErrorDescription = styled.div`
  font-size: 14px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #525F76;
  margin-bottom: 12px;
`;

const ErrorImage = styled.img`
  width: 226px;
  height: 124px;
`

let UserNotFound = () => {
    return (
        <ErrorPageContainer>
            <ErrorTitle>This link is Invalid</ErrorTitle>
            <ErrorDescription>Reach out to the meeting host for more information</ErrorDescription>
            <ErrorImage src={errorImage}></ErrorImage>
        </ErrorPageContainer>
    )
}

export default UserNotFound