import { createSelector } from 'reselect';
import { initialState } from '../reducers';

const makeSelectGlobalState = state => state.global || initialState;

const makeSelectCurrentPage = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.currentPage);

const makeSelectMeetingLink = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.meetingLink);

const makeSelectMeetingProvider = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.meetingProvider);

const makeSelectBookingDate = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.bookingDate);

const makeSelectBookingTime = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.bookingTime);

const makeSelectClockSettings = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.clockSettings);

const makeSelectTimeZoneString = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.timeZoneString);

const makeSelectIsBookingSlotUnavailable = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.bookingSlotUnavailable);

const makeSelectToken = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.token);

const makeSelectDetailsValues = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.details);

const makeSelectIsIframe = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.isIframe);

const makeSelectUnavailableSlots = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.unavailableSlots);

const makeSelectMeeting = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.data && globalState.data.meetingData);

const makeSelectUser = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.data && globalState.data.userData);

const makeSelectRouterId = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.data && globalState.data.routerId);

const makeSelectErrorPageInfo = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.errorPage);

const makeSelectIntegrationErrorPageInfo = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.integrationErrorPageDetails);

const makeSelectMeetingDetails = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.meetingDetails);

const makeSelectCurrentEndTime = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.currentEndTime);

const makeSelectCurrentTimeZoneOffset = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.currentTimeZoneOffset);

const makeSelectIntegrationDetails = () =>
    createSelector(makeSelectGlobalState, globalState => globalState.onboardUrls);

export {
    makeSelectCurrentPage,
    makeSelectBookingDate,
    makeSelectBookingTime,
    makeSelectClockSettings,
    makeSelectIsIframe,
    makeSelectMeeting,
    makeSelectRouterId,
    makeSelectUser,
    makeSelectDetailsValues,
    makeSelectUnavailableSlots,
    makeSelectMeetingLink,
    makeSelectMeetingProvider,
    makeSelectTimeZoneString,
    makeSelectToken,
    makeSelectErrorPageInfo,
    makeSelectMeetingDetails,
    makeSelectIsBookingSlotUnavailable,
    makeSelectCurrentEndTime,
    makeSelectCurrentTimeZoneOffset,
    makeSelectIntegrationDetails,
    makeSelectIntegrationErrorPageInfo
}