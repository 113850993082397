import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import ziLoader from '../../../assets/icons/black-loader-transperent.gif';
import queryString from 'query-string';
import {createStructuredSelector} from "reselect";
import * as selectors from "../../../selectors";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {REACT_APP_BASE_FE_URL} from "../../../config";

const PageLoaderContainer = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  margin: auto;
`;

const LoaderContainer = styled.div`
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: 100%;
  height: 100%;
  .zoomloader {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .zoomloaderImg {
      background: url(${ziLoader}) no-repeat center center;
      background-size: contain;
      margin: auto;
      max-width: 100px;
      max-height: 100px;
      height: 100%;
      width: 100%;
    }
  }
`;

const AuthPage = props => {
    const { token, verifyToken } = props;

    const openGoogleLoginPage = useCallback(() => {
        const search = window.location.search;
        const queryParams = queryString.parse(search);
        const redirect_uri = queryParams && queryParams.redirect_uri && decodeURIComponent(queryParams.redirect_uri);
        const token = queryParams && queryParams.token;
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const scope = ["openid", "profile", "email", "https://www.googleapis.com/auth/admin.directory.resource.calendar", "https://www.googleapis.com/auth/calendar"].join(' ');

        const params = {
            response_type: 'code',
            client_id: "55294326828-6tm7pir4vbt3n9khrorclt0lfsbo6437.apps.googleusercontent.com",
            redirect_uri: `${REACT_APP_BASE_FE_URL}integrations/google`,
            prompt: 'consent',
            access_type: 'offline',
            responseType: "code",
            scope,
            state: `${encodeURIComponent(redirect_uri)},${token}`,
        };

        const urlParams = new URLSearchParams(params).toString();
        window.location = `${googleAuthUrl}?${urlParams}`;
    }, []);

    const openMicrosoftLoginPage = useCallback(() => {
        const search = window.location.search;
        const queryParams = queryString.parse(search);
        const redirect_uri = queryParams && queryParams.redirect_uri && decodeURIComponent(queryParams.redirect_uri);
        const token = queryParams && queryParams.token;
        const microsoftAuthUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
        const scope = ["openid", "offline_access", "email", "profile", "https://graph.microsoft.com/Calendars.ReadWrite"].join(' ');
        const params = {
            response_type: 'code',
            client_id: "37d09790-2668-42ab-92d0-e1aa98f77f9d",
            redirect_uri: `${REACT_APP_BASE_FE_URL}integrations/microsoft`,
            prompt: 'consent',
            access_type: 'offline',
            response_mode: 'query',
            scope,
            state: `${encodeURIComponent(redirect_uri)},${token}`,
        };

        const urlParams = new URLSearchParams(params).toString();
        window.location = `${microsoftAuthUrl}?${urlParams}`;
    }, []);

    useEffect(() => {
        if(token.validated && token.provider === "google") openGoogleLoginPage();
        else if(token.validated && token.provider === "microsoft") openMicrosoftLoginPage();
        else if(!token.validated) verifyToken();
    })

    return (
        <div>
            <PageLoaderContainer  id="insent-page-loader-container">
                <LoaderContainer style={{ width: '50px', height: '50px', marginTop: '8%' }}>
                    <div className="zoomloader">
                        <div className="zoomloaderImg" />
                    </div>
                </LoaderContainer>
            </PageLoaderContainer>
        </div>
    );
};

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        token: selectors.makeSelectToken(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
        verifyToken: () => dispatch(actions.verifyToken.start()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);