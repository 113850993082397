import React, {useState, useRef, useEffect, useMemo} from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ZIAvatar from './../../components/ZIAvatar';
import LinkedInIcon from '../../../assets/icons/LinkedInIcon.png';
import LinkedInIconBlue from '../../../assets/icons/LinkedInIconBlue.png';
import * as globalActions from '../../../actions';
import { connect } from 'react-redux';
import {
    CalendarTitle,
    AvatarBar,
    YouAreBookingWithText,
    NameText,
    TextHolder,
    LinkedInNameBar,
    PickYourDayText,
    CalendarPageContainer,
    CalendarDateScrollContainer,
    CalendarTimeScrollContainer,
    RightSideCardContainer,
    LeftSideCardContainer,
    LinkedInImage,
    LinkedInImageBlue,
    CalendarFieldHeader,
    FieldContainer,
    CalendarTimeListContainer,
    CalendarDateListContainer,
    ArrowContainer,
    Separator,
    CalendarTimeItemWrapper,
    Time,
    BoldSpan,
    SemiBoldSpan,
    DashedLine,
    SkeletonDateContainer,
    SkeletonTimeContainer,
    ErrorMessage,
    ErrorMessageWrapper,
    FieldContainerHeader,
    DescriptionBar,
    ConfirmButtonHolder,
    SlotUnavailableMessage,
    SkeletonDiv
} from './styled';
import Button from '../../components/Button';
import { isEmpty } from '../../../utils/validation';
import CalendarDateItem from "../../components/CalendarDateItem";
import TimeBar from "../../components/TimeBar";
import ToggleSwitch from "../../components/ToggleSwitch";
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/right-arrow.svg';
import {
    convertToTime,
    twoWeekMilliSeconds,
    convertToDates,
    getReadableDate,
    sideScroll,
} from './utils';
import uuid from '../../../utils/uuid';
import { createStructuredSelector } from "reselect";
import {PAGES} from "../../../common/constants";
import * as selectors from "../../../selectors";
import VisibilitySensor from "react-visibility-sensor";
let calendarId = uuid();

const CalendarPage = ({
     userSettingColor,
     userSettings,
     message,
     Language,
     fetchMeetingSlots,
     bookMeetingSlot,
     processUserResponse,
     isCalendarLeftSpace,
     conversationListRef,
     userConfig,
     currentChannel,
     setPage,
     setBookingDate,
     setBookingTime,
     checkSlotAvailability,
     setClockSettings,
     isIframe,
     meeting,
     routerId,
     user,
     clockSettings,
     changeTimeZoneSettings,
     currentTimeZoneOffset,
     unavailableSlots,
     timeZoneString,
     setTimeZoneString,
     fetchMoreSlots,
     currentEndTimeOfSlots,
     ...props
     }) => {

    const calendarDateRef = useRef();
    const calendarTimeRef = useRef();
    const [leftSideContainerRef, setLeftSideContainerRef] = useState();
    const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

    let [calendarSlots, setCalendarSlots] = useState([]);
    let [activeSlot, setActiveSlot] = useState(null);
    let [activeSlotTime, setActiveSlotTime] = useState(null);
    let [slotError, setSlotError] = useState({
        slotDateError: null,
        slotTimeError: null
    });
    let [hoveredDate, setHoveredDate] = useState(null);

    let currentEndTime = new window.Date();
    currentEndTime.setHours(0, 0, 0, 0);
    let [bookingSlotTimeSpan, setBookingSlotTimeSpan] = useState({
        startTime: parseInt(window.Date.now() / 1000),
        endTime: parseInt((currentEndTime.getTime() + twoWeekMilliSeconds) / 1000),
        offset: -(currentEndTime.getTimezoneOffset() * 60),
    });

    let [isBooking, setIsBooking] = useState(false);
    let [isSlotsLoading, setIsSlotsLoading] = useState(true);
    let calendar = message && message.calendar;
    const [slotsApiError, setSlotsApiError] = useState(false);
    let firstTimeVisible = useRef(true);
    let [shouldDisableLeftArrow, setShouldDisableLeftArrow] = useState(true);
    let [shouldDisableRightArrow, setShouldDisableRightArrow] = useState(false);

    const rightTimeListContainerRef = useRef(null);
    const youPickedTextRef = useRef(null);

    let [dateScrollLeft, setDateScrollLeft] = useState(0)

    useEffect(() => {
        if(!meeting || !meeting.slots) return;
        handleNewSlotResponse(meeting.slots);
    }, [meeting])

    const handleScroll = (e) => {
        if(e.target.scrollLeft === 0 && shouldDisableLeftArrow === false) setShouldDisableLeftArrow(true);
        else if(e.target.scrollLeft !== 0 && shouldDisableLeftArrow === true) setShouldDisableLeftArrow(false);

        if((e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth) && shouldDisableRightArrow === false) setShouldDisableRightArrow(true);
        else if((e.target.scrollLeft + e.target.clientWidth !== e.target.scrollWidth) && shouldDisableRightArrow === true) setShouldDisableRightArrow(false);
    }

    useEffect(() => {
        if(calendarDateRef && calendarDateRef.current) {
            calendarDateRef.current.scrollLeft = dateScrollLeft;
            calendarDateRef.current.addEventListener('scroll', handleScroll, true);
        }
        return () => {
            if(calendarDateRef && calendarDateRef.current && calendarDateRef.current.scrollLeft !== dateScrollLeft)setDateScrollLeft(calendarDateRef.current.scrollLeft);
        }
    })

    useEffect(() => {
        setConfirmButtonLoading(false);
        for(let i=0; i< unavailableSlots.length; i++){
            if(activeSlot === unavailableSlots.slot && activeSlotTime === unavailableSlots.slotTime){
                setActiveSlotTime(null);
            }
        }
    }, [unavailableSlots])



    let scrollDateRight = () => {
        if (calendarDateRef && calendarDateRef.current) {
            sideScroll(calendarDateRef.current, 'right', 25, 100, 10);
        }
    };

    let scrollDateLeft = () => {
        if (calendarDateRef && calendarDateRef.current) {
            sideScroll(calendarDateRef.current, 'left', 25, 100, 10);
        }
    };

    let SkeletonVisibleHandler = (isVisible) => {
        if(isVisible && !firstTimeVisible.current)
        {
            const payload = { startTime: currentEndTimeOfSlots, offset: currentTimeZoneOffset}
            fetchMoreSlots(payload);
        }
        else {
            firstTimeVisible.current = false;
        }
    }

    const handleConfirm = () => {
        if(!activeSlot || !activeSlot) return;
        setBookingTime(activeSlotTime);
        setBookingDate(activeSlot);
        setConfirmButtonLoading(true);
        checkSlotAvailability({meta: {
            routerId,
            slot:activeSlot,
            slotTime: activeSlotTime,
            duration:meeting.duration,
            offset:meeting.offset
        }});
    }

    const calculateHeight = el => {
        let styles = window.getComputedStyle(el);
        let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

        return Math.ceil(el.offsetHeight + margin);
    }

    const handleNewSlotResponse = (data) => {
        setIsBooking(false);
        setIsSlotsLoading(false);

        if (data.err) {
            setSlotsApiError(true);
            return
        }
        setSlotsApiError(false);
        let processedData = convertToDates(data, bookingSlotTimeSpan.offset);
        setCalendarSlots([...processedData]);
        if (!activeSlot) {
            let newActiveSlot = processedData.find(slotData => slotData && slotData.slots && slotData.slots.length > 0);
            setActiveSlot(newActiveSlot)
            setActiveSlotTime(newActiveSlot && newActiveSlot.slots && newActiveSlot.slots[0]);
            setSlotError({
                slotDateError: null,
                slotTimeError: null,
            });
        }
        if (calendarDateRef && calendarDateRef.current) {
            calendarDateRef.current.scrollLeft = window[calendarId];
        }
    }

    const CalendarTimeItem = ({ time, active = true, disabled, isLast, isOdd, isFirst, userSettingColor, onSlotTimeSelect }) => {
        return (
            <CalendarTimeItemWrapper isOdd={isOdd} isFirst={isFirst} isLast={isLast} onClick={() => {if(!disabled)onSlotTimeSelect()}}>
                <Time
                    active={active}
                    disabled={disabled}
                    headerBackgroundColor={userSettingColor ? userSettingColor.headerBackgroundColor : null}
                >
                    {time}
                </Time>
            </CalendarTimeItemWrapper>
        );
    };

    const SetActiveTimeIndex = index => {
        if(index===1) setClockSettings({clockSettings: 24});
        else setClockSettings({clockSettings: 12});
    }

    const renderTimeSlots = (
        <FieldContainer margin="0 0 20px" ref={rightTimeListContainerRef}>
            <FieldContainerHeader ref={youPickedTextRef}>
                {!isEmpty(activeSlotTime) && activeSlot && <CalendarFieldHeader><SemiBoldSpan>Select a slot on </SemiBoldSpan><BoldSpan>{getReadableDate(activeSlot)}</BoldSpan></CalendarFieldHeader>}
                {slotError.slotTimeError && (<ErrorMessageWrapper position="relative" margin="0 6px 10px">
                    <ErrorMessage>{slotError.slotTimeError}</ErrorMessage>
                </ErrorMessageWrapper>)}
                <ToggleSwitch firstOption={null} secondOption={null} activeIndex={clockSettings?(clockSettings.clockSettings===12?0:1):0} setActiveIndex={ SetActiveTimeIndex } isLoading={confirmButtonLoading}/>
            </FieldContainerHeader>
            <CalendarTimeListContainer>
                <CalendarTimeScrollContainer ref={calendarTimeRef} leftSideContainerHeight={leftSideContainerRef ? leftSideContainerRef.offsetHeight : null}>
                    {isEmpty(activeSlotTime) ? new Array(14).fill(14).map((val, valId) => {
                        return (
                            <SkeletonTheme color="#2F3C4E33" highlightColor="#2F3C4E08" key={valId}>
                                <SkeletonTimeContainer isFirst={valId === 0}>
                                    <Skeleton height="20px" width="60px" duration={3} />
                                    <DashedLine />
                                </SkeletonTimeContainer>
                            </SkeletonTheme>
                        );
                    }) : activeSlot && activeSlot.slots && activeSlot.slots.map((slotTime, slotTimeId) => {

                        const selectSlotTime = () => {
                            if(confirmButtonLoading) return;
                            setActiveSlotTime(slotTime)
                            setSlotError({
                                ...slotError,
                                slotTimeError: null
                            });
                        }
                        let disabled = false;
                        for(let i=0; i<unavailableSlots.length; i++){
                            if(activeSlot.timestamp === unavailableSlots[i].slot.timestamp && slotTime === unavailableSlots[i].slotTime)
                                disabled = true;
                        }
                        return (
                            <CalendarTimeItem
                                key={slotTimeId}
                                disabled={disabled}
                                userSettingColor={userSettingColor}
                                active={slotTime === activeSlotTime}
                                time={slotTime === activeSlotTime?`${convertToTime(slotTime, (clockSettings ? clockSettings.clockSettings : 12))} - ${convertToTime(slotTime+meeting.duration, (clockSettings ? clockSettings.clockSettings : 12))}`:convertToTime(slotTime, (clockSettings ? clockSettings.clockSettings : 12))}
                                isFirst={slotTimeId === 0}
                                isOdd={activeSlot.slots.length % 2 === 0}
                                isLast={slotTimeId === activeSlot.slots.length - 1}
                                onSlotTimeSelect={selectSlotTime}
                            />
                        );
                    })}
                </CalendarTimeScrollContainer>
            </CalendarTimeListContainer>
        </FieldContainer>
    );

    const onWheel = (e) => {
        if(e.deltaY > 0) scrollDateRight()
        else if(e.deltaY < 0) scrollDateLeft()
    }

    const changeTimeZones = (offset, values) => {
        setPage(PAGES.LOADING_PAGE);
        setTimeZoneString(values);
        changeTimeZoneSettings(offset);
    }

    const CalendarDate = useMemo(() => {
        return (
                <CalendarDateScrollContainer onWheel={onWheel} ref={calendarDateRef} id="scroll">
                    {isEmpty(calendarSlots) ? new Array(14).fill(14).map((val, valId) => {
                        return (
                            <SkeletonDateContainer key={valId}>
                                <SkeletonTheme color="#2F3C4E33" highlightColor="#2F3C4E08">
                                    <Skeleton height="10px" width="30px" duration={3} />
                                    <div></div>
                                    <Skeleton height="20px" width="30px" duration={3} />
                                </SkeletonTheme>
                            </SkeletonDateContainer>
                        );
                    }) : calendarSlots.map((slot, slotsId) => {
                        let onSelectSlot = () => {
                            if(confirmButtonLoading) return;
                            setActiveSlot(slot);
                            setActiveSlotTime(slot.slots[0]);
                            if (calendarTimeRef.current) calendarTimeRef.current.scrollLeft = 0;
                            setSlotError({
                                ...slotError,
                                slotDateError: null
                            });
                        }
                        let isPreviousActive = (slotsId !== 0) && parseInt(calendarSlots[slotsId-1].timestamp) === (activeSlot && parseInt(activeSlot.timestamp));
                        let isActive = parseInt(slot.timestamp) === (activeSlot && parseInt(activeSlot.timestamp));
                        return (
                            <React.Fragment key={slotsId}>
                                {<Separator key={"separator"+slotsId} shouldShow={(slotsId !==0 && hoveredDate!==slotsId && !isActive && !isPreviousActive)}/>}
                                <CalendarDateItem
                                    onMouseEnter={() => {if(!slot.disabled)setHoveredDate(slotsId)}}
                                    onMouseLeave={() => {setHoveredDate(null)}}
                                    date={slot.date}
                                    arrayKey={slotsId}
                                    isFirst={slotsId === 0}
                                    active={isActive}
                                    isPreviousActive={isPreviousActive}
                                    disabled={slot.disabled}
                                    day={slot.day}
                                    month={slot.month}
                                    onSelectSlot={onSelectSlot}
                                />
                            </React.Fragment>
                        );
                    })}
                    <Separator key={"separator-visibility-sensor"} shouldShow={true}/>
                    <VisibilitySensor onChange={SkeletonVisibleHandler} containment={calendarDateRef.current}>
                        <SkeletonDateContainer>
                            <SkeletonDiv height={12} marginTop={3}/>
                            <SkeletonDiv height={20} marginTop={8}/>
                            <SkeletonDiv height={12} marginTop={8}/>
                        </SkeletonDateContainer>
                    </VisibilitySensor>
                </CalendarDateScrollContainer>
        );
    }, [meeting, calendarSlots, activeSlot, hoveredDate]);


    return (
        <CalendarPageContainer>
            <LeftSideCardContainer ref={newRef => setLeftSideContainerRef(newRef)} isIframe={isIframe} page={"calendar"} separator={true}>
                <CalendarTitle isIframe={isIframe}>{meeting.title}</CalendarTitle>
                {meeting.description && <DescriptionBar isIframe={isIframe}>{meeting.description}</DescriptionBar>}
                <TimeBar onChangeTimeZone={changeTimeZones} clockSettings={clockSettings} duration={meeting.duration} timezone={timeZoneString} isIframe={isIframe}/>
                {user && <AvatarBar onClick={ () => {window.open(user?.linkedinUrl, "_blank")} }>
                    <ZIAvatar img={user?.avatar} name={user?.name} />
                    <TextHolder>
                        <YouAreBookingWithText>You are booking with</YouAreBookingWithText>
                        <LinkedInNameBar>
                            <NameText>{user?.name}</NameText>
                            {user?.linkedinUrl && <LinkedInImage src={LinkedInIcon} />}
                            {user?.linkedinUrl && <LinkedInImageBlue src={LinkedInIconBlue} />}
                        </LinkedInNameBar>
                    </TextHolder>
                </AvatarBar>}
                <PickYourDayText isIframe={isIframe}>Pick a day that works for you</PickYourDayText>
                <CalendarDateListContainer>
                    <ArrowContainer disabled={shouldDisableLeftArrow} left={true} onClick={scrollDateLeft}>
                        <LeftArrow stroke={shouldDisableLeftArrow ? '#929DAB' : '#0061FF'}/>
                    </ArrowContainer>
                    {CalendarDate}
                    <ArrowContainer disabled={shouldDisableRightArrow} onMouseDown={(e)=>{e.preventDefault();return false;}} right={true} onClick={scrollDateRight}>
                        <RightArrow stroke={shouldDisableRightArrow ? '#929DAB' : '#0061FF'}/>
                    </ArrowContainer>
                </CalendarDateListContainer>
            </LeftSideCardContainer>
            <RightSideCardContainer isIframe={isIframe}>
                {renderTimeSlots}
                {unavailableSlots.length !==0 && <SlotUnavailableMessage>Sorry! The selected slot is unavailable now. Please pick another one.</SlotUnavailableMessage>}
                <ConfirmButtonHolder>
                    <Button slotUnavailable={activeSlotTime === null} loading={confirmButtonLoading} onClick={handleConfirm} text="Confirm"/>
                </ConfirmButtonHolder>
            </RightSideCardContainer>
        </CalendarPageContainer>
    );

}

export const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        isIframe: selectors.makeSelectIsIframe(),
        meeting: selectors.makeSelectMeeting(),
        user: selectors.makeSelectUser(),
        routerId: selectors.makeSelectRouterId(),
        clockSettings: selectors.makeSelectClockSettings(),
        unavailableSlots: selectors.makeSelectUnavailableSlots(),
        timeZoneString: selectors.makeSelectTimeZoneString(),
        currentEndTimeOfSlots: selectors.makeSelectCurrentEndTime(),
        currentTimeZoneOffset: selectors.makeSelectCurrentTimeZoneOffset(),
    });
};

export const mapDispatchToProps = dispatch => {
    return {
        setPage: payload => dispatch(globalActions.setPage(payload)),
        setBookingDate : payload => dispatch(globalActions.setBookingDate(payload)),
        setBookingTime : payload => dispatch(globalActions.setBookingTime(payload)),
        checkSlotAvailability: ({meta}) => dispatch(globalActions.checkSlotAvailability.start({meta})),
        setClockSettings : payload => dispatch(globalActions.setClockSettings(payload)),
        changeTimeZoneSettings : offset => dispatch(globalActions.changeTimeZone.start(offset)),
        setTimeZoneString: timezone => dispatch(globalActions.changeTimeZoneString(timezone)),
        fetchMoreSlots : payload => dispatch(globalActions.fetchMoreSlots.start(payload)),
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CalendarPage);

