import React from 'react';
import styled from 'styled-components';
import ziLoader from '../../../assets/icons/black-loader-transperent.gif';

const PageLoaderContainer = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  margin: auto;
`;

const LoaderContainer = styled.div`
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: 100%;
  height: 100%;
  .zoomloader {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .zoomloaderImg {
      background: url(${ziLoader}) no-repeat center center;
      background-size: contain;
      margin: auto;
      max-width: 100px;
      max-height: 100px;
      height: 100%;
      width: 100%;
    }
  }
`;

const LoadingPage = props => {
    return (
        <PageLoaderContainer  id="insent-page-loader-container">
            <LoaderContainer style={{ width: '50px', height: '50px', marginTop: '8%' }}>
                <div className="zoomloader">
                    <div className="zoomloaderImg" />
                </div>
            </LoaderContainer>
        </PageLoaderContainer>
    );
};

export default LoadingPage;