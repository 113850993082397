

export const twoWeekMilliSeconds = 1000 * 60 * 60 * 24 * 14;

export const weeks = ['Sun','Mon', 'Tue','Wed','Thu', 'Fri','Sat']
export const fullWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const fullMonths = ['January', 'February', 'March', 'April', 'May','June','July','August','September','October', 'November','December'];
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct', 'Nov','Dec'];

export const  isEmpty = (val) => {
  return (val === undefined || val == null || val.length <= 0) ? true : false;
}

export const convertToDates = (slots, offset) => {
  return Object.keys(slots).map(timestamp => {
    let date = new Date((timestamp - offset) * 1000);
    return {
      date: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
      day: weeks[date.getDay()],
      month: months[date.getMonth()],
      fullMonth: fullMonths[date.getMonth()],
      fullDay: fullWeeks[date.getDay()],
      year: date.getFullYear(),
      disabled: slots[timestamp].length === 0,
      timestamp: parseInt(timestamp),
      slots: slots[timestamp]
    }
  })
}

export const getReadableDate = (slot) => {
  return `${slot.date} ${slot.month}, ${slot.year}`;
}

export const convertToDayTime = (hours, minutes, clockSettings= 12) => {
  minutes = minutes < 10 ? '0'+minutes:minutes;
  if(clockSettings === 12){
    if (hours < 10)
      return '0' + hours + ':' + minutes + ' AM';
    else if (hours < 12)
      return hours + ':' + minutes + ' AM';
    else
      return (hours - 12) + ':' + minutes + ' PM';
  }
  else{
    if(hours < 10)
      return '0'+hours+':'+minutes;
    else
      return hours+':'+minutes;
  }

}

export const convertToMins = duration => {
  return `${Math.ceil(duration / 60)} Mins`;
}

export const getFullBookedTimeDetails = (slot, time, duration= 1800, clockSettings=12) => {
  if(!slot || isEmpty(time)) return;
  let startTime = convertToTime(time, clockSettings);
  let endTime = convertToTime(time+duration, clockSettings);
  //return `${startTime} - ${endTime}, ${slot.fullDay}, ${slot.fullMonth} ${slot.date}, ${slot.year}`;
  return `${startTime}  - ${endTime}`

}

export const getFullBookedTime = (slot, startTime, endTime, clockSettings=12) => {
  if(isEmpty(slot) || isEmpty(startTime) || isEmpty(endTime)) return;
  let start = convertToTime(startTime, clockSettings);
  let end = convertToTime(endTime, clockSettings);
  //return `${startTime} - ${endTime}, ${slot.fullDay}, ${slot.fullMonth} ${slot.date}, ${slot.year}`;
  return `${start}  - ${end}`
}

export const getFullBookedDate = (slot, time, duration= 1800) => {
  if(!slot) return;
  return `${slot.date} ${slot.fullMonth}, ${slot.year}`
}

export const getDateWithUTCOffset = (inputTzOffset) => {
  var now = new Date(); // get the current time

  var currentTzOffset = -now.getTimezoneOffset() / 60 // in hours, i.e. -4 in NY
  var deltaTzOffset = (inputTzOffset/60) - currentTzOffset; // timezone diff

  var nowTimestamp = now.getTime(); // get the number of milliseconds since unix epoch
  var deltaTzOffsetMilli = deltaTzOffset * 1000 * 60 * 60; // convert hours to milliseconds (tzOffsetMilli*1000*60*60)
  var outputDate = new Date(nowTimestamp + deltaTzOffsetMilli) // your new Date object with the timezone offset applied.

  return outputDate;
}

export const getFullBookedTimeWithDuration = (slot, time, duration= 1800) => {

}
export const convertToTime = (seconds, clock= 12) => {
  let hours = Math.floor(Math.abs(seconds) / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  if(clock === 12)
  {
    let time = ' AM';
    if (parseInt(hours) > 11) {
      time = ' PM';
      hours = parseInt(hours) - 12;
    }

    if (hours === 0) {
      hours = "12"
    } else if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return `${hours}:${minutes}${time}`;
  }
  else if(clock ===24){
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    return `${hours}:${minutes}`
  }
};

function getTimezoneName(date) {
  const short = date.toLocaleDateString(undefined);
  const full = date.toLocaleDateString(undefined, { timeZoneName: 'long' });
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  } else {
    return full.substr(11);;
  }
}

export const getReadableBookedTime = (startTimestamp = 0, endTimestamp = 0, short = false, dateOnly = false,) => {
  let startDate = new Date(startTimestamp * 1000);
  let endDate = new Date(endTimestamp * 1000);

  let startTime = timestampToReadableTime(startDate)
  let endTime = timestampToReadableTime(endDate)

  let shortTimeZone = getTimezoneName(startDate).split(' ').map(word => word[0]).join('');
  return `${weeks[startDate.getDay()] || '--'} ${startDate.getDate() || '--'} ${months[startDate.getMonth()] || '--'}${!dateOnly ? `, ${startTime}${!short ? ` to ${endTime} ${shortTimeZone}` : ''}` : ''}`
}

export const timestampToReadableTime = (date) => {

  let hours = date.getHours();
  let time = 'am';
  if (parseInt(hours) > 11) {
    time = 'pm';
    hours = parseInt(hours) - 12;
  }

  if (hours === 0) {
    hours = "12"
  } else if (hours < 10) {
    hours = '0' + hours;
  }
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  };
  return `${hours}:${minutes}${time}`
}


export const convertSecondsToMin = (seconds) => {
  return Math.floor(seconds / 60);
};

export const sideScroll = (element, direction, speed, distance, step) => {
  let scrollAmount = 0;
  var slideTimer = setInterval(function () {
    if (direction === 'left') {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}