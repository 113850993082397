import styled, {keyframes} from 'styled-components';
import React from 'react';

const DotKeyframes = keyframes`
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.8);
    }
    60% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
`;

const LoadingDots = styled.div`
    text-align: center;
    width: 100%;

    & div {
      margin-right: 5px;
      animation: ${DotKeyframes} 1.2s infinite ease-in-out;
      background-color: #eeeeee;
      border-radius: 10px;
      display: inline-block;
      height: 7px;
      width: 7px;
    }

    & div:nth-child(1)
    {
    animation-delay: .3s;
    }
    & div:nth-child(2) 
    {
        animation-delay: .6s;
    }
    
    & div:nth-child(3) 
    {
        animation-delay: 0.9s;
    }
`;

const DotLoader = () => {

    return (
      <LoadingDots>
          <div></div>
          <div></div>
          <div></div>
      </LoadingDots>
    );
}

export default DotLoader;