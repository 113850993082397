import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import history from './utils/history';
import configureStore from './ui/store/configureStore';
import App from './ui/containers/App';
import GlobalStyle from './styles';
import theme from './styles/theme';
import * as serviceWorker from './serviceWorker';

const store = configureStore({}, history);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <App />
                <GlobalStyle />
            </React.Fragment>
        </ThemeProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
