import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import {DEVICE_WIDTH, ANIMATION_DELAY} from "../../../common/constants";

const ActionButton = styled.button`
  cursor: pointer;
  width: 392px;
  font-family: "Sharp Sans Bold" !important;
  height: 44px;
  background: ${p => p.loading ? (p.slotUnavailable ? '#F0F1F4' : '#0041c0') : (p.slotUnavailable ? '#F0F1F4' : '#0061FF')};
  color: ${p => p.textColor ? (p.slotUnavailable ? '#A3AEBF;':p.textColor) : (p.slotUnavailable ? '#A3AEBF;':'#FFFFFF')};
  border-radius: 20px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  justify-self: end;
  &:hover{
    ${p => p.slotUnavailable ? '':`background-color: #0041c0;
    transition: background-color ${ANIMATION_DELAY}ms linear;`
    }
  }
  border: none;
  @media (max-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: ${p => p.mobileWidth ? p.mobileWidth : 'calc(100vw - 50px)'};
    margin-top: 0;
  }
`;


const Button = ({onClick, text, bgColor, loading, textColor, buttonRef, type, isSubmitting, mobileWidth, slotUnavailable}) => {
    return (
        <ActionButton slotUnavailable={slotUnavailable} type={type} loading={loading} ref={buttonRef} onClick={() => { if(!loading && typeof onClick === "function") onClick() }} bgColor={bgColor} textColor={textColor} disabled={isSubmitting || slotUnavailable} mobileWidth={mobileWidth}>
            {loading && <CircularProgress size={20} color="inherit" style={{marginLeft:"-48px",marginRight:"24px"}}/>}
            {text.toString()}
        </ActionButton>
    );
}

export default Button;